import React from "react";
import styled from "styled-components";
import { addMinutes, addHours, format, addYears, isFuture, parse,differenceInMilliseconds } from 'date-fns';
import { mother, father, brother, sister, wife, husband, son, daughter, neutralPerson } from '../assets/images';
import { transgender } from "../assets/icons";
import { CardTitle } from "../components/atoms";
import { Flex } from "rebass";
import themes from "../themes";
import enUS from 'date-fns/locale/en-US';
import offerTagGreen from "../assets/icons/offer_tag_green.svg";
import discountTagGreen from "../assets/icons/discount-tag-green.svg";
import pigginBankGreen from "../assets/icons/green-piggi-bank.svg";
import ValuePlusAPI from "../services/value-plus.api";

const Span = styled.span`
  text-align: center;
  font-size: ${props => props.theme.fontSizes[props.fontSize]};
  font-family: ${({ theme: { fonts }, font = 'normal' }) => fonts[font]};
  color: ${({ theme: { colors }, color }) => color ? colors[color] : 'inherit'};
`;
const SettingsContainer = styled.div`
    min-width: 210px;
    min-height: 50px;
    background-color: white;
    position: absolute;
    z-index: 2;
    right: -8px;
    top: 35px;
    border-radius: 5px;
    box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.15);
    &:after {
      content:"";
      position: absolute;
      right: 8px;
      top: -10px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent #fff transparent;
      z-index:2;
    }
  `

const SettingsCell = styled(Flex)`
    border-bottom: ${props => '1px solid ' + props.theme.colors.borderColor};
    padding-top: 10px;
    padding-bottom: 5px;
  `

export const queryParams = (query) => {
  if (query[0] === '?') {
    query = query.slice(1)
  }
  return query.split('&').filter(Boolean).reduce((accumulator, element) => {
    const key = element.split('=')[0]
    const value = element.split('=').slice(1).join("=")
    return { ...accumulator, [key]: value }
  }, {})
}

export const dateParse = (...args) => {
  let dateObj = parse(...args)
  if (
    args[0] && typeof (args[0]) === 'string' && args[0].indexOf('PM') !== -1 &&
    dateObj && dateObj.getHours() !== 12
  ) {
    // date-fns doesn't convert pm to correct date. so need pre processing.
    dateObj = addHours(dateObj, 12)
  }
  return dateObj;
};

export const timeDifference = (selectedTime) => {
  let parsedSelectedTime;
  const dateFormat = "yyyy-MM-dd HH:mm:ss XXXX";
  if(typeof selectedTime === 'string') parsedSelectedTime = parse(selectedTime, dateFormat, new Date());
  const currentTime = new Date();
  const distance = differenceInMilliseconds(parsedSelectedTime, currentTime);
  const minDiff = Math.floor(distance / 1000 / 60);
  const days = Math.floor(distance / (24 * 60 * 60 * 1000));
  const hours = Math.floor((distance % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60));
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);
  return { distance, minDiff, days, hours, minutes, seconds };
}

export const dateFormat = (...args) => format(...args);

export const timeStringInIndianTimeZone = (date) => {
  try {
    return format(addMinutes(date, 330 + date.getTimezoneOffset()), 'hh:mm a', { locale: enUS });
  } catch (error) {
    return ''; 
  }
}

export const dateInIndianTimeZone = (date) => {
  try {
    return addMinutes(date, -330 - date.getTimezoneOffset());
  } catch (error) {
    return ''; 
  }
}


export const dateWeekDayAndDate = (date) => ({ day: format(date, "iii"), date: format(date, "dd") });

export const isMemberChild = (dateOfBirth) => {
  return dateOfBirth && isFuture(addYears(new Date(dateOfBirth), 18))
}

export const capitalize = (data) => {
  return data?.charAt(0)?.toUpperCase() + data?.slice(1)?.toLowerCase();
}

export const validateMobileNumber = (mobile) => {
  return mobile?.trim().length === 10 && /[6-9][0-9]{9}/.test(mobile)
}

export const dateMonthNameYear = (date, ordinal = false) => {
  if (!date) { return "" }
  let newDate = new Date(date)
  if (newDate == "Invalid Date") { return "" }
  let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  let dateWithOrdinal = newDate.getDate()
  let n = newDate.getDate();
  if (ordinal) {
    dateWithOrdinal = n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');
  }
  return dateWithOrdinal + " " + months[newDate.getMonth()] + " " + newDate.getFullYear()
}

export const dayName = (date) => {
  if (!date) { return "" }
  let newDate = new Date(date)
  if (newDate == "Invalid Date") { return "" }
  let days = ["", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
  return days[newDate.getDay()]
}

export const isMobile = () => {
  let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  return isMobile
}


export const isiOS = navigator.userAgent.match('iPad') || navigator.userAgent.match('iPhone') || navigator.userAgent.match('iPod')

export const isAndroid = navigator.userAgent.match('Android');

export const requestSource = () => isMobile() ? 'pwa-mobile' : 'pwa-web';

export const familyRelationIcons = {
  Father: father,
  Wife: wife,
  Husband: husband,
  Sister: sister,
  Brother: brother,
  Son: son,
  Daughter: daughter,
  Mother: mother
}

export const familyRelationIcon = (member) => {
  return member?.gender === 'Non-binary' ? transgender : familyRelationIcons[member?.relation] || otherFamilyRelationIcon(member)
}

export const otherFamilyRelationIcon = (member) => {
  if (member?.gender === 'Male') {
    if (member.age <= 18) {
      return son;
    } else if (member.age > 18 && member.age < 30) {
      return brother;
    } else if (member.age >= 30 && member.age < 45) {
      return husband;
    } else {
      return father;
    }
  } else if (member?.gender === 'Female') {
    if (member.age <= 18) {
      return daughter;
    } else if (member.age > 18 && member.age < 30) {
      return sister;
    } else if (member.age >= 30 && member.age < 45) {
      return wife;
    } else {
      return mother;
    }
  } else if (member?.relation === 'Mother in law') {
    return mother;
  } else if (member?.relation === 'Father in law') {
    return father;
  } else if (member?.gender === 'Non-binary') {
    return transgender;
  } else {
    return neutralPerson;
  }
}

export const animationDefaultConfig = { stiffness: 400, damping: 20 }

export const getUserAgent = () => window.navigator.userAgent;


export const loadDynamicScript = (type, callback) => {
  const mapId = type == 'map' ? 'googleMap' : type
  const existingScript = document.getElementById(mapId);


  let scriptSrc = {
    googleMap: { src: 'https://maps.googleapis.com/maps/api/js?components=country:in&key=AIzaSyB86e4qiDOFTUd84V81-4RaOUP3D1n3hoo&libraries=places&type=sublocality&language=en', id: 'googleMap' },
    razorpay: { src: 'https://checkout.razorpay.com/v1/checkout.js', id: 'razorpay' }
  }

  if (!existingScript) {
    const script = document.createElement('script');
    script.src = scriptSrc[mapId].src
    script.id = scriptSrc[mapId].id
    document.body.appendChild(script);

    script.onload = () => {
      if (callback) callback();
    };
  }

  if (existingScript && callback) callback();
};


export const checkValidDob = (dob) => {
  if (dob) {
    let dateFields = dob.split("-").map((item) => parseInt(item))
    if (!dateFields[0] || !dateFields[1] || !dateFields[2]) { return true }
    let newDate = new Date(dateFields[0], dateFields[1], dateFields[2])
    let isLeapYear = new Date(newDate.getFullYear(), 1, 29).getDate() === 29
    if (newDate.getDate() > 31) { return true }
    if (dateFields[1] > 12) { return true }
    if (newDate.getFullYear() < 1900) { return true }
    if ([4, 6, 9, 11].includes(dateFields[1]) && newDate.getDate() > 30) { return true }
    if (dateFields[1] === 2 && (!isLeapYear && newDate.getDate() > 28)) { return true }
    if (dateFields[1] === 2 && (isLeapYear && newDate.getDate() > 29)) { return true }
  }
  else {
    return true
  }
  return false
}

export const addErrorsToDob = (dob, checkFutureDate) => {
  if (dob) {
    let dateFields = dob.split("-").map((item) => parseInt(item))
    if (dateFields[0] && dateFields[1] && dateFields[2]) {
      let newDate = new Date(dateFields[0], dateFields[1], dateFields[2])
      let isLeapYear = new Date(newDate.getFullYear(), 1, 29).getDate() === 29
      if (checkFutureDate && new Date(dob) > new Date()) { return "Date of birth can't be future date" }
      if (newDate.getDate() > 31) { return 'Enter valid date' }
      if (dateFields[1] > 12) { return 'Enter valid date' }
      if (newDate.getFullYear() < 1900) { return 'Enter valid date' }
      if ([4, 6, 9, 11].includes(dateFields[1]) && newDate.getDate() > 30) { return 'Enter valid date' }
      if (dateFields[1] === 2 && (!isLeapYear && newDate.getDate() > 28)) { return 'Enter valid date' }
      if (dateFields[1] === 2 && (isLeapYear && newDate.getDate() > 29)) { return 'Enter valid date' }
    }
    else {
      return 'Enter valid date'
    }
  }
}


// ['companyName.ekincare.com'].includes(window.location.host)

export const isInsuranceRole = (/icicilombard/i).test(window.location.pathname)

export const formatNumberWithCommas = (number) => Intl.NumberFormat('en-IN').format(number)


export const formatRupees = (amount, styles) => {
  let total = amount?.toString().includes('₹') || typeof amount == 'string' ? amount : [null, '', undefined].includes(amount) ? null : (!isNaN(amount) && Math.round(amount) != amount ? `₹${amount.toFixed(2)}` : `₹${Math.abs(amount)}.00`)
  if (!total) {
    return null
  }
  total = total.split('.')
  return <>{total[0]}<Span color={styles?.color} font={styles?.font}>.{total[1]}</Span> </>

}
export const isEmpty = (value) => {
  return value === undefined || value === null || value === "" || value?.length === 0 || value[0] === "" || (typeof(value) === 'object' && Object.keys(value).length === 0) || value === false
}


export const debounce = (func, delay) => {
  let timer;
  return function () {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, arguments);
    }, delay);
  }
}

const inRange = (value, min, max) => value >= min && value <= max

export const ConvertKMsToMins = (distance) => {
  if (isEmpty(distance)) {
    return distance
  }
  const mins = parseInt((distance / 10) * 60) // 10 km/hr is speed of customer
  let text;
  if (mins <= 3) {
    text = `3-5`
  }
  else if (inRange(mins, 4, 8)) {
    text = `5-10`
  }
  else if (inRange(mins, 9, 13)) {
    text = `10-15`
  }
  else if (inRange(mins, 14, 18)) {
    text = `15-20`
  }
  else if (inRange(mins, 19, 23)) {
    text = `20-25`
  }
  else if (inRange(mins, 24, 28)) {
    text = `25-30`
  }
  else if (inRange(mins, 29, 33)) {
    text = `30-35`
  }
  else if (inRange(mins, 34, 38)) {
    text = `35-40`
  }
  else if (inRange(mins, 39, 43)) {
    text = `40-45`
  }
  else if (inRange(mins, 44, 48)) {
    text = `45-50`
  }
  else if (inRange(mins, 49, 54)) {
    text = `50-55`
  }
  else {
    text = 1
  }
  return `${text === 1 ? 'More than an hour' : `${text} mins`} away`
}

export const Settings = ({ settings, choosenAddress, chooseAddress, editAddress, deleteChoosenAddress }) => {
  return (
    <SettingsContainer>
      {settings.includes('Select') &&
        <SettingsCell justifyContent='start' px='10px' onClick={() => chooseAddress(choosenAddress)}>
          <CardTitle ml='10px' mb='0px' pb='0px' >Select</CardTitle>
        </SettingsCell>
      }
      {settings.includes('Edit') &&
        <SettingsCell justifyContent='start' px='10px' onClick={() => editAddress(choosenAddress)}>
          <CardTitle ml='10px' mb='0px' pb='0px'>Edit</CardTitle>
        </SettingsCell>
      }
      {settings.includes('Delete') &&
        <SettingsCell justifyContent='start' px='10px' onClick={() => deleteChoosenAddress(choosenAddress.id)}>
          <CardTitle ml='10px' mb='0px' pb='0px'>Delete</CardTitle>
        </SettingsCell>
      }
    </SettingsContainer>
  )
}

export const getBenefitLabels = (configuration) => {
  const { spending_account_enabled, spending_account, cashback, discount_meta_data, discount_upto,
    cashback_upto, cashback_amount, cost, cost_meta_data, dont_show_price, form_type } = configuration
  let benefitsBoldText, benefitsText, benefitCardText = { text: '', icon: '' }
  let pharmacyDiscount = false

  if (dont_show_price) {
    benefitsBoldText = ''
    benefitsText = ''
    benefitCardText = {text: '', icon: ''}
  } else if (spending_account_enabled) {
    if (spending_account.available_balance >= 0) {
      benefitsBoldText = spending_account.available_balance
      benefitsText = 'Available Balance'
      benefitCardText = { text: spending_account.available_balance, icon: '' }
    } else {
      if (cashback) {
        benefitCardText = { text: `Up to 20% off`, icon: discountTagGreen}
        if (discount_upto) {
          benefitsBoldText = discount_upto
          benefitsText = discount_meta_data
          pharmacyDiscount = true
        } else if (cashback_upto) {
          benefitsBoldText = cashback_upto
          benefitsText = 'Cashback'
        } else {
          benefitsBoldText = cashback_amount
          benefitsText = 'Cashback'
        }
      } else {
        benefitsBoldText = cost
        benefitsText = cost_meta_data
      }
    }
  } else if (cashback) {
    if (discount_upto) {
      benefitsBoldText = discount_upto
      benefitsText = discount_meta_data
      benefitCardText = { text: `Up to ${discount_upto} off`, icon: discountTagGreen}
      pharmacyDiscount = true
    } else {
      benefitsBoldText = +cashback_upto ?? +cashback_amount
      benefitsText = 'Cashback'
      benefitCardText = { text: `Up to ${cashback_upto ?? cashback_amount} off`, icon: discountTagGreen}
    }
  } else if (cost > 0) {
    benefitsBoldText = cost
    benefitsText = cost_meta_data
    benefitCardText = {text: `Starting from ₹${formatNumberWithCommas(parseInt(cost))}`, icon: discountTagGreen} 
  } else {
    benefitsBoldText = 'Free'
    benefitCardText = {text: 'Fully sponsored', icon: offerTagGreen} 
    benefitsText = cost_meta_data
  }

  benefitsBoldText = typeof benefitsBoldText === 'number' ? `₹${formatNumberWithCommas(parseInt(benefitsBoldText))}` : benefitsBoldText
  benefitCardText['text'] = typeof benefitCardText.text === 'number' ? `₹${formatNumberWithCommas(parseInt(benefitCardText.text))}` : benefitCardText.text 

  if ( spending_account_enabled && spending_account?.available_balance >= 0 && !spending_account?.restrict_sub_limit ) {
    benefitsBoldText = `Upto ${benefitsBoldText}`
    benefitCardText = {text: `Sponsored up to ${benefitCardText.text}`, icon: pigginBankGreen} 
  }
  else if(cashback) {
    benefitsBoldText =`Upto ${benefitsBoldText}`
    benefitCardText = { text: `${benefitsBoldText} off`, icon: discountTagGreen}
  } else if (form_type === "reimbursement") {
    benefitCardText = {text: `Raise claims for bookings`, icon: pigginBankGreen}  
  }  
  return [benefitsBoldText, benefitsText, pharmacyDiscount, benefitCardText]

}

export const splitFromZero = (value) => value = value.charAt(0) === '0' ? value.charAt(1) : value

export const getDate = (date, monthName) => {
  let month = monthName.split(" ")[0].substring(0, 4).toLowerCase()
  month = capitalize(month)
  let [year, monthValue, day] = date.split('-')
  monthValue = splitFromZero(monthValue)
  day = splitFromZero(day)
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const currentDay = new Date().getDate();
  if (year == currentYear && monthValue == currentMonth) {
    if (currentDay == day) return 'Today'
    else if (currentDay - 1 == day) return 'Yesterday'
  }
  return `${day} ${month}`
}

export const toTitleCase = (phrase) => {
  return String(phrase)
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const toProperCase = (phrase) => {
  return String(phrase).replace(/[^a-zA-Z ]/g, " ").replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const removeHtmlElement = (phrase) => {
  return String(phrase).replace(/(<([^>]+)>)/ig, '')
}

export const trendsColorMap = () => {
  return {
    'text-no-color': `${themes.colors.darkestGrey}`,
    'text-success': `${themes.colors.green}`,
    'text-warning': `${themes.colors.darkYellow}`,
    'text-danger': `${themes.colors.red}`,
  }
}

export const getBillValue = (data) => {
  const service_type = data?.paymentDetails?.service_type
  const selling_price = data?.paymentDetails?.payment_details?.amount_to_be_deducted
  const customers = data.customers

  let billValue = 0
  if (service_type === 'health_checks') {
    billValue = selling_price
  } else {
    customers && customers.map(customer => {
      customer.selected_packages.map(item => {
        billValue += item.mrp ?? 0
      });
    });
  }
  return billValue
}

export const modifyUrlForNewBenefits = (urlLink) => {
  let urlArray = urlLink.split('.com/')
  let deeplink = urlArray[1]
  if (deeplink?.includes('ahc')) {
    deeplink = 'new-benefits/ahc'
  } else if (deeplink?.includes('nhc') || deeplink?.includes('health-checks')) {
    deeplink = 'benefits/health-checks'
  } else if (deeplink?.includes('dental')) {
    deeplink = 'new-benefits/dental'
  } else if (deeplink?.includes('vision')) {
    deeplink = 'new-benefits/vision'
  } else if (deeplink?.includes('flu')) {
    deeplink = 'new-benefits/flu_vaccination'
  } else if (deeplink?.includes('covid')) {
    deeplink = 'new-benefits/covid_vaccination'
  }
  urlArray[1] = deeplink
  return urlArray.join('.com/')
}

export const getTime = (hours) => {
  let newHh = hours
  if (hours === '12') {
    newHh = '12'
  } else if (hours === "01") {
    newHh = '13'
  } else if (hours === "02") {
    newHh = '14'
  } else if (hours === "03") {
    newHh = '15'
  } else if (hours === "04") {
    newHh = '16'
  } else if (hours === "05") {
    newHh = '17'
  } else if (hours === "06") {
    newHh = '18'
  } else if (hours === "07") {
    newHh = '19'
  } else if (hours === "08") {
    newHh = '20'
  } else if (hours === "09") {
    newHh = '21'
  }
  return newHh
}

export const getDateName = (weekday) => {
  let name = ""
  if (weekday === 0) {
    name = 'Sun'
  } else if (weekday === 1) {
    name = 'Mon'
  } else if (weekday === 2) {
    name = 'Tue'
  } else if (weekday === 3) {
    name = 'Wed'
  } else if (weekday === 4) {
    name = 'Thu'
  } else if (weekday === 5) {
    name = 'Fri'
  } else if (weekday === 6) {
    name = 'Sat'
  }
  return name
}

export const recentNotificationsCount = (notifications) => notifications && notifications.recent && notifications.recent.total_count || 0

export const formatToTwoDecimalPoint = (amount) => !isNaN(amount) && Math.round(amount) != amount ? `${amount.toFixed(2)}` : `${Math.abs(amount)}.00`

export const clientSource = () => {
  if (window.isAndroidWebView()) return 'android';
  if (window.isIOSWebView()) return 'ios';
  if (isMobile()) return 'pwa-mobile';
  return 'pwa-web';
}

export const ambulanceTapToCall = (e, contactNumber) => {
  e.stopPropagation()
  const link = contactNumber ? `tel:${contactNumber}` : 'tel:1800-212-0688';
  window.location.href = link;
}

const UsingStyle = (type) => {
  switch(type) {
  case 'steps':
    return " background: linear-gradient(to right, #14A36F, #035E2C); -webkit-background-clip: text; -webkit-text-fill-color: transparent;"
  case 'calories':
    return " background: linear-gradient(to right, #FF8F54, #E66740); -webkit-background-clip: text; -webkit-text-fill-color: transparent;"
  default:
    return 
  }
}

export const modifyText = (text, value, type) => {
  const replacedWord = `<span style="font-family: ProximaNova-Extrabld; font-size: 14px;${UsingStyle(type)}">&nbsp;${value}&nbsp;</span>`
  const replacedSentence = text.replaceAll(value, replacedWord);
  return replacedSentence;
} 

export const durationList = [
  { id: 'today', title: 'Today' }, 
  { id: 'this week', title: 'This week' }, 
  { id: 'this month', title: 'This month' }, 
  { id: 'all', title: 'Life time' }, 
  { id: 'stepathon', title: 'Stepathon' }
];

export const individualDurationList = [
  { id: 'this week', title: 'This week' }, 
  { id: 'this month', title: 'This month' }, 
  { id: 'last month', title: 'Last month' },
  { id: 'this year', title: 'This year' }, 
  { id: 'all', title: 'Life time' }, 
  { id: 'stepathon', title: 'Stepathon' }
];

export const discountCalculator = (amount, discountPercentage) => { 
  const discountValue = amount * (discountPercentage / 100)
  return isNaN(discountValue) ? 0 : discountValue
}

export const formatAddress = (selectedAddress) => {
  const { line1, line2, city, state, country } = selectedAddress || {};
  const addressString = [line1, line2, city, state, country].filter(part => !isEmpty(part));
  return addressString.join(", ");
}

export const validateMobileCountryWise = (mobile, isdCode) => {
  if(isdCode !== '+91') {
    return /^[0-9]{5,20}$/.test(mobile) && mobile.slice(0, 20)
  }
  return mobile?.trim().length === 10 && /[6-9][0-9]{9}/.test(mobile) && mobile.slice(0, 10)
}


export const availableSubscriptions = async() => {
  try {
    const response = await ValuePlusAPI.availableSubscriptions()
    return response.data.subscriptions
  }catch(err) {
    return []
  }
}