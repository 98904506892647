import styled, { css } from 'styled-components';
import { Card as Base } from 'rebass';

const Card = styled(Base).attrs(({ flexDirection }) => ({
  flexDirection
}))`
  ${({ top }) => top && css`top: ${top};`}
  ${({ bottom }) => bottom && css`bottom: ${bottom};`}
  ${({ left }) => left && css`left: ${left};`}
  ${({ right }) => right && css`right: ${right};`}
  ${({ position }) => position && css`position: ${position};`}
  box-sizing: border-box;
  word-wrap: break-word;
  background-clip: border-box;
  display: flex;
  ${({ flexDirection }) => flexDirection && css`flex-direction: ${flexDirection};`}
  ${({ borderRequired, borderSize}) => {
  return borderRequired && css`border: ${borderSize} solid ${props => props.theme.borders[props.borderColor] || props.theme.colors.border};`
}}
  ${({ borderColor, borderSize}) => {
  return borderColor && css`border: ${borderSize} solid ${props => props.theme.colors[props.borderColor]};`
}}
  ${({ br }) => br && css`border-right: ${br}`};
  ${({ bl }) => bl && css`border-left: ${bl}`};
  ${({ borderTopColor, theme: { colors } }) => borderTopColor && css`border-top: 1px solid ${colors[borderTopColor]}`};
  ${({ borderBottomColor, theme: { colors } }) => borderBottomColor && css`border-bottom: 1px solid ${colors[borderBottomColor]}`};
  ${({ borderRadius }) => borderRadius && css`border-radius: ${borderRadius};`}
  ${({ backgroundColor, theme: { colors } }) => backgroundColor && css`background-color: ${colors[backgroundColor]};`}
  ${({ height }) => height && css`height: ${height};`}
  ${({ minHeight }) => minHeight && css`min-height: ${minHeight};`}
  ${({ minWidth }) => minWidth && css`min-width: ${minWidth};`}
  ${({ cursor }) => cursor && css`cursor: ${cursor};`}
  ${({ alignContent }) => alignContent && css`text-align: ${alignContent};`}
  ${({ alignItems }) => alignItems && css`align-items: ${alignItems};`}
  ${({ justifyContent }) => justifyContent && css`justify-content: ${justifyContent};`}
  ${({ pointer }) => pointer && css`cursor: pointer;`}
  ${({ opacity }) => opacity && css`opacity: ${opacity};`}
  ${({ zIndex }) => zIndex && css`z-index: ${zIndex};`}

  ${({ boxShadow }) => {
  return boxShadow && css`
      box-shadow: ${boxShadow};
    `
}}
  ${({ flex }) => {
  return flex && css`
      flex: ${flex};
    `
}}
  
  ${({ active }) => {
  return active && css`
      background-color: ${({ theme: { colors } }) => colors.primary};
      color: ${({ theme: { colors } }) => colors.white};
    `
}}

  ${({ disabled }) => {
  return disabled && css`
      opacity: .7;
      cursor: not-allowed;
    `
}}

  ${props => {
  return props.hoverBorderColor && css`
      &:hover {
        border: 1px solid ${props => props.theme.borders[props.hoverBorderColor]};
      }
    `
}}
`

Card.defaultProps = {
  flexDirection: 'column',
  backgroundColor: 'white',
  height: null,
  borderRequired: true,
  active: false,
  disabled: false,
  borderRadius: '0.25em',
  position: 'relative',
  borderSize: '1px'
}
export default Card;