import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { Card, CardTitle, CardText, Button, Icon, Input, Image, ToggleVisibility } from '../../atoms';
import greentick from '../../../assets/icons/green-tick.svg';
import loadingWhite from '../../../assets/icons/loading-white.svg';
import { Flex, Box } from 'rebass';
import { media } from '../../../utils/mediaBreakpoints';
import AddedMember from '../../../assets/images/added-member.svg';
import HomeAPI from '../../../services/home.api';

const Wrapper = styled.div`
	text-align: ${props => props.textAlign};
	position: relative;
	min-height: 450px;
	${media.mobile`
		min-height: 100%;
	`}
`

const Footer = styled.div`
	position: absolute;
	bottom: 0px;
	width: 100%;
`

const Success = ({goBackToOriginPage}) => {
  const [shared, updateShared] = useState(false);
  const [openShareForm, updateShareForm] = useState(false);
  const [email, updateEmail] = useState(undefined);
  const [loading, updateLoading] = useState(false);

  const shareInstructions = () => {
    updateLoading(true)
    let body = {email: email}
    HomeAPI.shareLoginInstructions({data: body}, onSuccess, onFailure)
  }

  const onSuccess = () => {
    updateLoading(false)
    updateShared(true)
  }

  const onFailure = () => {
    updateLoading(false)
  }

  const updateEmailId = (e) => {
    updateEmail(e.currentTarget.value);
  }

  const validEmail = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

  const ShareForm = () => {
    let disabled = !validEmail.test(email)
    return(
      <>
        <Input name='email' type='email' value={email} width='100%' placeholder='Enter a valid email to share the instructions' onChange={updateEmailId} />
        <Button loading={loading} url={loadingWhite} type='primary' disabled={disabled} size='lg' block={true} onClick={shareInstructions}>Share</Button>
      </>
    )
  }

  return(
    <Card px='20px' py='20px' width='100%'>
      <Wrapper textAlign='start'>
        <CardTitle alignSelf='right' mb='5px' onClick={goBackToOriginPage}>X</CardTitle>
        <ToggleVisibility visibleOn={{desktop : false, tablet : false}}>
          <Flex justifyContent='center'>
            <Image src={AddedMember} />
          </Flex>
        </ToggleVisibility>
        <CardTitle mt='30px' fontSize='lg' mb='3px' pb='3px'>Congratulations!</CardTitle>
        <CardText mb='20px' pb='0px'>Your family member is now added on ekincare</CardText>
        <CardTitle fontSize='sm'>Benefits that can be availed</CardTitle>
        <Flex width='100%'>
          <Box width={['7%']}><Icon src={greentick} size='md'/></Box>
          <Box width={['93%']}><CardText fontSize='xs' ml={4}>Connect to expert doctors in less than a minute</CardText></Box>
        </Flex>
        <Flex width='100%'>
          <Box width={['7%']}><Icon src={greentick} size='md'/></Box>
          <Box width={['93%']}><CardText fontSize='xs' ml={4}>Maintain all their health records in one place</CardText></Box>
        </Flex>
        <Flex width='100%'>
          <Box width={['7%']}><Icon src={greentick} size='md'/></Box>
          <Box width={['93%']}><CardText fontSize='xs' ml={4}>Book preventive health checkup and order medicines at a discounted rate</CardText></Box>
        </Flex>
        {shared ?
          <Footer>
            <CardText color='green'>Email sent: Login instructions has been sent successfully.</CardText>
          </Footer>
          :
          <Fragment>
            {openShareForm ?
              <Footer>{ShareForm()}</Footer> :
              <Footer>
                <CardText>Share login instructions and let your family member take charge of their health.</CardText>
                <Button block={true} size='lg' type='primary' onClick={()=>goBackToOriginPage()} mb='12px'>Done</Button>
                <Button block={true} size='lg' type='default-outline' data-event-name="afm_mobile_number" onClick={()=>updateShareForm(true)}>Share Login Instructions</Button>
              </Footer>
            }
          </Fragment>	
        }	
      </Wrapper>
    </Card>
  )	

}

export default Success;