import styled from 'styled-components';

const RectDivider = styled.div`
	width: 100%;
	height: 10px;
	background-color: ${props => props.theme.colors.light};
	margin-top: 20px;
	margin-bottom: 20px;
`

export default RectDivider