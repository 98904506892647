import React, { useState, Fragment } from 'react';
import styled, {css} from 'styled-components';
import HomeAPI from '../../../services/home.api';
import { useUserDetails } from '../../../utils/customHooks';
import { Card, CardTitle, Image, Banner, Icon } from '../../atoms';
import { RadioButtonGroup, Modal } from '../../molecules';
import { media, excludeMedia } from '../../../utils/mediaBreakpoints';
import { mother, father, addOther, neutralPerson } from '../../../assets/images';
import blueTick from '../../../assets/icons/blue-tick.svg';
import { withRouter } from 'react-router-dom';
import { Flex } from 'rebass';
import Name from './Name';
import DateOfBirth from './DateOfBirth';
import MobileNumber from './MobileNumber';
import Success from './Success';
import AlreadyAdded from './AlreadyAdded';
import AddedMember from '../../../assets/images/added-member.svg';
import { backBlack, closeBlack, transgender } from '../../../assets/icons';
import tracker from '../../../utils/tracker';
import EmptyPage from '../../../pages/EmptyPage';
import { Text } from '../../atoms/typography';
import { familyRelationIcon } from '../../../utils/common';
import { LAB_TESTS } from '../../../routes/private';

const CardTick = styled(Image)`
${media.mobile`
  right: -8px;
  top: -8px;
  height:20px;
  width:20px;
`}
${excludeMedia.mobile`
  height: 20px;
  width: 20px;
  left: 0px;
  top: 0px
`}
position: absolute;
z-index: 2;
`

const CardBox = styled(Card)`
min-width: 150px;
position: relative;
${media.mobile`
  min-width: 80px;
`}
`

const ContentWrapper = styled.div`
text-align: center;
width: 100%;
`

const Wrapper = styled.div`
background-color: ${props => props.theme.colors.white};
padding: 20px;
text-align: left;
`

const Container = styled.div`
display: flex;
width: 100%;
`
const SuccessWrapper = styled.div`
${media.mobile`
  display: none;
`}
`

const Box1 = styled.div`
width: 100%;
-webkit-transition: width .5s;
${props => {
  return props.openedBox2 && css`
    ${media.desktop`
      width: 60%;
    `}
    ${media.tablet`
      width: 60%;
    `}
    ${media.mobile`
      display: none;
    `}      
  `
}}
`

const Box2 = styled.div`
width: 40%;
position: absolute;
right: -650px;
min-height: 600px;
-webkit-transition: right .5s;
${props => {
  return props.openedBox2 && css`
    right 0;   
  `
}}
${media.mobile`
  display: none;
`}
${media.tablet`
  width: 40%;
`}
`

const MobileView = styled.div`
${excludeMedia.mobile`
  display: none;
`}
`

const AddFamilyMembers = ({ history, closeModal }) => {
  const{ user, familyMembers, requireDataUpdation, services } = useUserDetails()
  const [familyMembersList] = useState(familyMembers)
  const [relations] = useState(['Father', 'Mother', 'Spouse/Significant other', 'Children', 'Siblings', 'Other'])
  const [addMember, updateAddMember] = useState(false);
  const [selectedRelation, updateRelation] = useState('');
  const [step, updateStep] = useState(1);
  const [gender, updateGender] = useState(undefined);
  const [name, updateName] = useState(undefined);
  const [dob, updateDateOfBirth] = useState(undefined);
  const [mobileNumber, updateMobileNumber] = useState(undefined);
  const [skipMobile, updateSkipMobile] = useState(false);
  const [mobileAlreadyTaken, updateMobileTaken] = useState(false);
  const [loading, updateLoading] = useState(false);
  const [openAddedSiblingsChildrenModal, setOpenAddedSiblingsChildrenModal] = useState(false);

  const CardImage = (relation) => {
    if(relation === 'Father'){
      return father
    } else if(relation === 'Mother'){
      return mother
    } else if(relation === 'Spouse/Significant other'){
      return neutralPerson;
    } else if(relation === 'Siblings'){
      return neutralPerson
    } else if(relation === 'Children'){
      return neutralPerson
    } else if(relation === 'Other'){
      return addOther
    }
  }

  const addFamilyMember = (rel, isAdded) => {
    if (isAdded && ['Children', 'Siblings'].includes(rel)) {
      updateRelation(rel);
      setOpenAddedSiblingsChildrenModal(true);
      return;
    }
    if(openAddedSiblingsChildrenModal) setOpenAddedSiblingsChildrenModal(false);
    updateRelation(rel)
    updateAddMember(true);
    updateName('');
    updateStep(1);
    tracker.track('afm_select_relation',{'relation':rel})
  }

  const updateMemberName = (e) => {
    const { value } = e.currentTarget
    updateName(value)
  }

  const updateMemberGender = (item) => {
    updateGender(item)
  }

  const updateDob = (value) => {
    updateDateOfBirth(value)
  }

  const updateMobile = (e) => {
    let mobile = e.currentTarget.value.replace(/\D/g,'');
    updateMobileNumber(mobile.slice(0,10))
    updateMobileTaken(false)
  }

  const discardFlow = () => {
    updateRelation('')
    updateAddMember(false)
    updateDateOfBirth(null)
    updateSkipMobile(false)
    updateStep(1)
    updateName(undefined)
  }

  const addNumber = () => {
    updateSkipMobile(false)
  }

  const registerMember = () => {
    updateLoading(true)
    const relation = {
      'Father': 'father', 
      'Mother': 'mother', 
      'Spouse/Significant other': 'spouse', 
      'Children': 'child', 
      'Siblings': 'sibling', 
      'Other': 'other'
    }
    let body = { guardian_id: user.id, relation: relation[selectedRelation], gender: gender, first_name: name, last_name: '', date_of_birth: dob }
    if (!skipMobile){
      body['mobile_number'] = mobileNumber
    }
    requireDataUpdation(false)
    HomeAPI.addFamilyMember({data: body}, onSuccess, onFailure)
  }

  const onSuccess = () => {
    updateStep(4)
    updateLoading(false)
    requireDataUpdation(true)
  }

  const onFailure = (error) => {
    updateLoading(false)
    if (error?.response?.status === 409){
      updateMobileTaken(true)
    }
  }

  const memberAlreadyAdded = () => {
    let existing_relations = familyMembersList.map((item) => item['relation'])
    return existing_relations.includes(selectedRelation)
  }

  const goBackToOriginPage = () => {
    if(closeModal) {
      closeModal()
      return
    }else if (history.location?.state?.isFromLabTest){
      history.push(LAB_TESTS , {isFromMembers: true, selectedPackage: history.location.state.selectedPackage, selectedCustomers: history.location.state.selectedCustomers})
      return
    }
    history.goBack()
  }

  const List = () => {
    let result = []
    let existing_relations = familyMembersList.map((item) => {
      if (item['relation'] === 'Child') return {relation: 'Children', gender: item.gender};
      if (item['relation'] === 'Sibling') return {relation: 'Siblings', gender: item.gender};
      return {relation: item['relation'], gender: item.gender};
    })
    relations.map((relation)=>{
      let isSelected = relation === selectedRelation
      const isAdded = existing_relations.find(relationTemp => relationTemp.relation === relation);
      result.push({component:
        <CardBox p={[4, 5]} mx={[3, 5]} width={['90px', 'auto']} height={['90px','auto']} my={4} hoverBorderColor='primary' borderColor={isSelected ? 'primary' : undefined} onClick={()=> addFamilyMember(relation, isAdded)}>
          {isSelected && <CardTick src={blueTick} /> }
          {isAdded && <Banner>Added</Banner>}
          <ContentWrapper>
            <Image src={isAdded && ['Father', 'Mother'].includes(isAdded.relation) && isAdded?.gender === 'Non-binary'? transgender : CardImage(relation)} width={['40px', '100px']} height={['40px', '100px']}/>
            <CardTitle textAlign='center' whiteSpace='nowrap' textOverflow='ellipsis' overflow="hidden" width={["100%", "100px"]} fontSize={['xs','md']} ml={['0px', '8px']}>{relation}</CardTitle>
          </ContentWrapper>
        </CardBox>})
    })
    return result
  }

  if(services.add_family_member === 0 || familyMembers.length > 8){
    const text = services.add_family_member === 0 ? 'This feature is not available for your organisation. Please contact your HR to enable it.' : 'You can only add up to 8 family members. For any update please contact customer support.'
    return (
      <Wrapper>
        <EmptyPage
          header={{text: 'Warning'}}
          body={{text: text}}
          clickToAction={{text: closeModal ? 'Close' : 'Go back', onClick: goBackToOriginPage}} 
        />
      </Wrapper>
    )
  }

  return(
    <Fragment>
      <Container>
        <Box1 openedBox2={addMember}>
          {step == 4 ?
            <SuccessWrapper>
              <Card px='20px' py='20px' minHeight='500px'>
                <Image src={AddedMember} />
              </Card>
            </SuccessWrapper>
            :
            <Wrapper>
              <Flex>
                <Icon size={'md'} src={backBlack} mt={['3px','7px']} mr={'10px'} onClick={goBackToOriginPage}/>
                <CardTitle fontSize={['md','lg']} mb='20px' bolder>Whom do you want to add?</CardTitle>
              </Flex>  
              <Flex >
                <RadioButtonGroup list={List()} justifyContent={['space-between','left']}/>
              </Flex>
            </Wrapper>
          }	
        </Box1>
        <Box2 openedBox2={addMember}>
          {memberAlreadyAdded() && ['Father', 'Mother', 'Spouse/Significant other'].includes(selectedRelation) ?
            <Flex ml='10px' width='100%'><AlreadyAdded relation={selectedRelation} goBack={goBackToOriginPage} discardAddMember={discardFlow} /></Flex>
            :
            selectedRelation && <Flex ml='10px' width='100%'>
              {step === 1 ? <Name relation={selectedRelation} gender={gender} updateGender={updateMemberGender} name={name} updateName={updateMemberName} goBack={goBackToOriginPage} goToNext={()=>updateStep(2)} discardAddMember={discardFlow} /> : null}
              {step === 2 ? <DateOfBirth relation={selectedRelation} dob={dob} updateDob={updateDob} goBack={()=>updateStep(1)} goToNext={()=>updateStep(3)} discardAddMember={discardFlow} /> : null}
              {step === 3 ? <MobileNumber loading={loading} skipped={skipMobile} onSkip={()=>updateSkipMobile(true)} 
                addNumber={addNumber} relation={selectedRelation} mobileNumber={mobileNumber} 
                updateMobile={updateMobile} goToNext={()=>updateStep(4)} goBack={()=>updateStep(2)} 
                discardAddMember={discardFlow} registerMember={registerMember} 
                mobileAlreadyTaken={mobileAlreadyTaken} /> : null}
              {step === 4 ? <Success goBackToOriginPage={goBackToOriginPage} /> : null}
            </Flex> 
          }
        </Box2>
        <MobileView>
          {addMember ?
            <Modal size='md' textAlign='left' visibleOn={{mobile: true}} closeModal={discardFlow} height='100%'>
              {memberAlreadyAdded() && ['Father', 'Mother', 'Spouse/Significant other'].includes(selectedRelation) ?
                <AlreadyAdded relation={selectedRelation} goBack={goBackToOriginPage} discardAddMember={discardFlow} />
                :
                <Flex width='100%' >
                  {step === 1 ? <Name img={CardImage(selectedRelation)} relation={selectedRelation} gender={gender} updateGender={updateMemberGender} name={name} updateName={updateMemberName} goBack={discardFlow} goToNext={()=>updateStep(2)} discardAddMember={discardFlow} /> : null}
                  {step === 2 ? <DateOfBirth relation={selectedRelation} dob={dob} updateDob={updateDob} goBack={()=>updateStep(1)} goToNext={()=>updateStep(3)} discardAddMember={discardFlow} /> : null}
                  {step === 3 ? <MobileNumber loading={loading} skipped={skipMobile} onSkip={()=>updateSkipMobile(true)} 
                    addNumber={addNumber} relation={selectedRelation} mobileNumber={mobileNumber} 
                    updateMobile={updateMobile} goToNext={()=>updateStep(4)} goBack={()=>updateStep(2)} 
                    discardAddMember={discardFlow} registerMember={registerMember} 
                    mobileAlreadyTaken={mobileAlreadyTaken} /> : null}
                  {step === 4 ? <Success goBackToOriginPage={goBackToOriginPage} /> : null}	
                </Flex>
              }	
            </Modal>
            : null 
          }
        </MobileView>
        {openAddedSiblingsChildrenModal && <AddedSiblingsChildrenModal familyMembers={familyMembers} selectedRelation={selectedRelation} onClose={() => setOpenAddedSiblingsChildrenModal(false)} onAddFamilyMember={addFamilyMember}/>}
      </Container>
    </Fragment>
  )
}

function AddedSiblingsChildrenModal({ onClose, selectedRelation, familyMembers, onAddFamilyMember }) {
  const CHILDREN_SIBLINGS = { Children: 'Child', Siblings: "Sibling" };
  const alreadyAddedSiblingOrChildren = familyMembers.filter(member => member.relation === CHILDREN_SIBLINGS[selectedRelation])

  const Member = ({name, icon, onClick}) => {
    return (
      <Card flexDirection="column" justifyContent="center" alignItems="center" p="12px" borderRadius="8px" onClick={onClick && onClick} cursor={onClick ? "pointer" : "unset"}>
        <Image src={icon} height="40px" widht="40px"/>
        <CardTitle mt="8px" mb="-5px" whiteSpace='nowrap' textOverflow='ellipsis' overflow="hidden" width="60px" textAlign="center" fontSize="sm" lineHeight="14px">{name}</CardTitle>
      </Card>
    )
  }

  const ExtFlex = styled(Flex)`
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;
    padding: 16px;
    padding-top: 0px;
  `

  return (
    <Modal closeModal={() => onClose(false)} type="modal-root" closeOnClickOverlay={true} direction="bottom-top">
      <Card borderRadius="8px" width={["100%", '80%']} maxHeight="content-height" flexDirection="column" backgroundColor="backgroundColor">
        <Flex alignItems='center' justifyContent="space-between" p={["16px 24px", "29px 32px"]}>
          <Text fontSize="md" color="bannerBackground" bolder>Added {selectedRelation}</Text>
          <Icon src={closeBlack} onClick={() => onClose(false)} size='md' pointer/>
        </Flex>
        <ExtFlex>
          {alreadyAddedSiblingOrChildren?.map(member => <Member name={member?.name} icon={familyRelationIcon(member)} key={member?.name} />)}
          <Member name={"Other"} icon={addOther} onClick={() => onAddFamilyMember(selectedRelation, false)}/>
        </ExtFlex>
      </Card>
    </Modal>
  )
}

export default withRouter(AddFamilyMembers);