import React from 'react';
import Modal from './Modal'
import { Image, CardTitle, Card, Wrapper } from '../atoms';
import paymentLoader from '../../assets/images/payment-loader.svg';

const PaymentLoader = () => {

  return(
    <Modal size='xxs' closeOnClickOverlay={false} closeIcon={false} >
      <Card px='20px' py='20px' width='100%'>
        <Wrapper alignSelf='center'>
          <Image src={paymentLoader} height='100px' />
          <CardTitle>Don't refresh or close</CardTitle>
        </Wrapper>
      </Card>
    </Modal>
  )
}

export default PaymentLoader;
