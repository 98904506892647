import styled from 'styled-components';
import { Box } from 'rebass';

const OverlineText = styled(Box).attrs(() => ({as: 'span'}))`
	margin-bottom: 0.35em;
	color: ${props => props.theme.colors.grey};
  font-size: 0.75rem;
  font-family: ProximaNova, sans-serif;
  line-height: 2.66;
  letter-spacing: 0.05333em;
  text-transform: uppercase;
  margin: 0;
  display: block;
`

export default OverlineText