import React from 'react'
import { Flex } from 'rebass'
import styled, { css } from 'styled-components'
import { addToCart, backBlack, searchDrug } from '../../assets/icons'
import { media } from '../../utils/mediaBreakpoints'
import { Icon } from '../atoms'
import { Text } from '../atoms/typography'
import IconWithNotificationsCount from './IconWithNotificationsCount'

export const ExtendedFlex = styled(Flex)`
  position: fixed;
  top: ${props => props.top ? props.top : props.isModal ? '0px' : '60px'};
  width: 100%;
  left: 0;
  z-index: 1;
  ${({borderBottom}) => borderBottom && css `border-bottom: 1px solid ${p => p.theme.borders.borderGrey}`};
  ${media.mobile`
    top: 0;
  `}
`

const Header = ({onGoBack, borderBottom = true, secondTitleClickHandler, fontFamily = 'bold', fontSize='lg', text, secondTitle, benefitType, onClickCartView, count, step, searchIconRequired, onClickSearchIcon, mt, isModal, top, addNewFamilyMember, ml=['12px', '-52px']}) => {
  return (
    <ExtendedFlex backgroundColor={'white'} py='20px' pr={['16px', 0]} borderBottom={borderBottom} justifyContent={'center'} isModal={isModal} top={top}>
      <Flex alignItems={'center'} width={isModal ? '100%' : ['100%', '640px']} ml={isModal ? '12px' : ml}>
        <Icon src={backBlack} size='md' mr='21px' data-testid='back-icon' onClick={onGoBack} pointer />
        <Text color='title' fontSize={fontSize} mt={mt} lineHeight='20px' bold={fontFamily === 'bold'} bolder={fontFamily === 'bolder'}>{text}</Text>
      </Flex>
      {searchIconRequired && <Flex ml={'92px'}>
        <Icon src={searchDrug} size='md' onClick={onClickSearchIcon} />
      </Flex>}
      {secondTitle && <Text ml={['95px', '210px']} bold fontSize='sm' color='blue' whiteSpace='nowrap' cursor='pointer' onClick={secondTitleClickHandler}>{secondTitle}</Text>}
      {['ahc', 'dental', 'vision', 'flu_vaccination', 'covid_vaccination', 'nhc'].includes(benefitType) && count > 0 && (step === 1 || (step === 10 && !addNewFamilyMember)) && <IconWithNotificationsCount onClick={onClickCartView} borderRadius='50%' fontSize={'xxs'} countWidth='17px' iconBackgroundColor={'red'} count={count} src={addToCart} size='lg' />}
    </ExtendedFlex>
  )
}

Header.defaultProps = {
  searchIconRequired: false,
  isModal: false
}

export default Header
