import React from 'react';
import styled from 'styled-components';
import { Flex } from 'rebass';
import { Icon } from '../atoms';
import { moderateInfo } from '../../assets/icons'
import { Text } from '../atoms/typography';

const ExtendedFlex = styled(Flex)`
  background-color: #FFF8E4;
  margin-left: -24px;
`
const InfoBanner = ({text}) => {
  return (
    <ExtendedFlex alignItems={'center'} flexDirection='row' py={'12px'} px={5}>
      <Icon src={moderateInfo} size='xl' />
      <Text ml={'12px'} color='extraInformation' fontSize='md' bold>{text}</Text>
    </ExtendedFlex>
  )
}

export default InfoBanner