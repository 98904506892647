const tracker = {
  track: (eventName, eventAttributes) => {
    /** Add your events processing here.
    */

    if(window.isEkincareApp()) {
      let payload = { eventType: eventName, attributes: [] }
      if(eventAttributes){
        payload = {...payload}
        if(eventAttributes['screen_name'])
          payload['screen_name'] = eventAttributes['screen_name']
        else
          payload['screen_name'] = null
              
        if(eventAttributes['action'])
          payload['action'] = eventAttributes['action']
        else
          payload['action'] = null   

        const filteredKeys = Object.keys(eventAttributes).filter(key => key !== 'screen_name' || key !== 'action');
        const attrArray = []
        filteredKeys.forEach(key => {
          attrArray.push({key: key, value: eventAttributes[key]})
        })

        payload['attributes'] = attrArray
      }

      let nativeEventData = JSON.stringify(
        {
          action: 'trackEvent',
          payload: payload
        }
      )
      
      window.postAndroidMessage(nativeEventData)
      window.postIOSMessage(nativeEventData)
    }

    else if(window.Moengage) {
      if(eventAttributes){
        window.Moengage.track_event(eventName, eventAttributes);
      } else {
        window.Moengage.track_event(eventName);
      }
    }
  }
}

export default tracker; 