import { lazy } from 'react';

// Add in imports for  this folder here
import Button from './Button';
import ToggleButton from './ToggleButton';
import Input from './Input';
import Label from './Label';
import Card from './Card';
import Checkbox from './Checkbox';
import Toast from './Toast';
import Image from './Image';
import Main from './Main';
import Heading from './Heading';
import Text from './Text';
import ListElement from './ListElement';
import Icon from './Icon';
import ToolTip from './ToolTip';
import Popover from './Popover';
import Dropdown from './Dropdown';
import Switch from './Switch';
import SearchBox from './SearchBox';
import Badge from './Badge';
import Link from './Link';
import Radio from './Radio';
import DropdownRoot from './DropdownRoot';
import DropDownSection from './DropDownSection';
import HorizontalMenu from './HorizontalMenu';
import DropDownWrapper from './DropDownWrapper';
import NavGroup from './NavGroup';
import Progressbar from './Progressbar';
import Wrapper from './Wrapper';
import Line from './Line';
import ArrowDown from './ArrowDown';
import ArrowUp from './ArrowUp';
import NoShowInMobile from './NoShowInMobile';
import ToggleVisibility from './ToggleVisibility';
import Banner from './Banner';
import ThreeDotLoader from './ThreeDotLoader'
import CardBody from './CardBody'
import CardFooter from './CardFooter'
import Alert from './consultations/Alert'
import UnorderedList from './UnorderedList'
import InputCounter from './InputCounter';
import Sticker from './Sticker';
import LinkWithIcon from './LinkWithIcon';
import Alignment from './Alignment';
import ShowMore from './ShowMore';
import ShowLess from './ShowLess';
import RectDivider from './RectDivider';
import RouteLink from './RouteLink';
import CardHeader from './CardHeader';
import FormAlert from './FormAlert';
import FixedHeader from './FixedHeader';
import FixedBody from './FixedBody';
import FixedFooter from './FixedFooter';
import Tag from './Tag';
import ListItemsWithShape from './ListItemsWithShape';
import InfoBanner from './InfoBanner';
import ConsultationButton from './ConsultationButton';
import Circle from './Circle'
import OfferBanner from './OfferBanner';
import InputWithAction from './InputWithAction';
import HorizontalLineWithTextAtCenter from './HorizontalLineWithTextAtCenter'
import ETA from './ETA';
import SelectInput from './SelectInput';
import {ButtonText, CardTitle, CardLink, CardSubTitle, CardText, CaptionText, MenuText, MenuItemText, OverlineText, PageHeader, SectionHeading, SectionSubHeading, SectionText, NavbarItemTitle} from './typography';
import CustomDatePickerWithAction from './CustomDatePickerWithAction';
import CardWithScroll from './CardWithScroll';
import PharmacySavingDetailsCard from './PharmacySavingDetailsCard';
import CardWithTitleDescriptionIcon from './CardWithTitleDescriptionIcon';

const LottieContainer = lazy(() => import('./LottieContainer'));

export {
  Button, ToggleButton, Input, Badge, Link, Label, Card, Checkbox, Toast, Image, Main, Heading, Text,
  ListElement, ToolTip, Icon, Dropdown, Popover, Switch, ButtonText, CardTitle, CardLink,
  CardSubTitle, CardText, CaptionText, MenuText, MenuItemText, OverlineText, PageHeader,
  SectionHeading, SearchBox, Radio,DropdownRoot, DropDownSection,
  DropDownWrapper, HorizontalMenu, SectionSubHeading, SectionText, NavbarItemTitle,
  NavGroup, Wrapper, Line, Progressbar, ArrowDown, NoShowInMobile, LottieContainer,
  ToggleVisibility, Banner, ArrowUp, ThreeDotLoader, CardBody, CardFooter, Alert, UnorderedList,
  InputCounter, Sticker, LinkWithIcon, Alignment, ShowMore, ShowLess, RectDivider, RouteLink,
  CardHeader, FormAlert, FixedHeader, FixedBody, FixedFooter, Tag, InfoBanner, ConsultationButton, ListItemsWithShape,
  Circle, OfferBanner, InputWithAction, HorizontalLineWithTextAtCenter, ETA, CustomDatePickerWithAction, SelectInput,
  CardWithScroll, PharmacySavingDetailsCard, CardWithTitleDescriptionIcon
};
