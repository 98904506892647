import React from 'react';
import styled from 'styled-components';
import { Card, CardTitle, CardText, Button, Image, Icon, ToggleVisibility } from '../../atoms';
import {Flex, Box} from 'rebass';
import BackIcon from '../../../assets/icons/back.svg';
import InfoRed from '../../../assets/icons/info-red.svg';
import skipPhone from '../../../assets/images/skip-phone.svg';
import { media } from '../../../utils/mediaBreakpoints';
import loadingWhite from '../../../assets/icons/loading-white.svg';
import { validateMobileNumber } from '../../../utils/common';
import { MobileNumber as MobileNumberInput } from '../../molecules';
import { mobileIcon } from '../../../assets/images';
import NewFreshChat from '../../../utils/NewFreshChat';


const Wrapper = styled.div`
	text-align: ${props => props.textAlign};
	position: relative;
	min-height: ${props => props.minHeight || '500px'};
	${media.mobile`
		min-height: 100%;
	`}
	-webkit-transition: min-height .5s;
`

const Footer = styled.div`
	position: absolute;
	bottom: 0px;
	width: 100%;
`

const ErrWrapper = styled.div`
	background-color: ${props => props.theme.colors.lightestRed};
	margin: 0px -20px;
	padding: 20px;
`

const MobileNumber = ({relation, loading, mobileNumber, skipped, onSkip, updateMobile, goBack, addNumber, registerMember, mobileAlreadyTaken, discardAddMember}) => {
	
  const isDisabled = () => {
    let disabled = false
    if (!mobileNumber || mobileNumber.trim() === "" || !validateMobileNumber(mobileNumber.trim())){
      disabled = true
    }
    return disabled
  }

  

  const ErrorMsg = () => {
    if (!skipped && mobileAlreadyTaken){
      return(
        <ErrWrapper>
          <Flex width='100%' flexWrap='wrap'>
            <Box width={['10%']}>
              <Icon src={InfoRed} />
            </Box>
            <Box width={['90%']}>
              <CardTitle fontSize={'sm'}>Number Already Taken</CardTitle>
              <CardText>This number is already registered with another user. You might have used this number for another family member. if you think this is wrong contact our customer support</CardText>
              <NewFreshChat type='button'/>
            </Box>
          </Flex>
        </ErrWrapper>	
      )
    }
    else { return null }	
  }

  if (skipped) {
    return(
      <Card px='20px' py='20px' ml='10px' width='100%'>
        <Wrapper textAlign='start'>
          <CardTitle alignSelf='right' onClick={discardAddMember}>X</CardTitle>
          <Flex justifyContent='center'><Image src={skipPhone} /></Flex>
          <CardText>If you don't provide a phone number, your family member will not be able to login to ekincare. Only you will be able to access ekincare service on their bahalf.</CardText>
          <Footer>
            <Button block={true} size='lg' type='primary' data-event-name="afm_mobile_number" data-event-data={JSON.stringify({'screen':'skip' ,'user_action':'add_num_proceed'})} onClick={addNumber}>Add Number and Proceed</Button>
            <Button block={true} disabled={loading} size='lg' mt='10px' type='default-outline' data-event-name="afm_mobile_number" data-event-data={JSON.stringify({'screen':'skip','user_action':'proceed_without_num'})} onClick={registerMember}>Proceed without Number</Button>
          </Footer>
        </Wrapper>
      </Card>		
    )
  }

  return(
    <Card px='20px' py='20px' width='100%'>
      <Wrapper textAlign='start' minHeight={!skipped && mobileAlreadyTaken ? '570px' : undefined}>
        <Flex width='100%' flexWrap='wrap'>
          <Box width={['50%']}>
            <Image src={BackIcon} width='20px' onClick={goBack} />
          </Box>
          <Box width={['50%']}>
            <CardTitle alignSelf='right' onClick={discardAddMember}>X</CardTitle>
          </Box>
        </Flex>
        <ToggleVisibility visibleOn={{desktop : false, tablet : false}}>
          <Flex justifyContent='center'>
            <Image src={mobileIcon} width='100%' height='200px' mb='66px' />
          </Flex>
        </ToggleVisibility>
        <CardTitle mb='3px' pb='3px' bold>Enter your {relation === 'Other' ? 'family member' : (relation.toLowerCase()+"'s")} mobile number?</CardTitle>
        <CardText mb='15px' color={['#7C8CAB', 'inherit']} >Your family member will be able to login to their account using this number</CardText>
        <MobileNumberInput width='100%' placeholder='Mobile Number' value={mobileNumber} data-testid="mobile-input" onChange={updateMobile} />
        <ErrorMsg />
        <Footer>
          {isDisabled() ?
            <Button block={true} size='lg' type='stateless' disabled={true}>Register</Button>
            :
            <Button loading={loading} url={loadingWhite} block={true} size='lg' type='primary' data-event-name="afm_mobile_number" data-event-data={JSON.stringify({'user_action':'register'})} onClick={registerMember}>Register</Button>
          }
          <Button block={true} size='lg' mt='10px' type='default-outline' data-event-name="afm_mobile_number" data-event-data={JSON.stringify({'screen':'skip'})} onClick={onSkip}>Skip</Button>
        </Footer>
      </Wrapper>
    </Card>
  )	

}

export default MobileNumber;