import React from 'react';
import { Flex } from 'rebass';
import { accessDenied } from '../../assets/images';
import EmptyPage from '../EmptyPage';
import styled from 'styled-components';
import { excludeMedia } from '../../utils/mediaBreakpoints';
import { HOME } from '../../routes/private';

const ErrorContainer = styled(Flex)`
  justify-content: center;
  align-items: center;
  ${excludeMedia.mobile`
    margin-top: ${({ theme: { space }}) => `${space[7]}px`}
  `}
`

const AccessDeniedPage = () => (
  <ErrorContainer>
    <EmptyPage
      height={['80vh', '100%']}
      image={{src: accessDenied, width: '30%'}}
      header={{text: 'Access Denied'}}
      body={{text: `Please contact your HR for more details.`}}
      clickToAction={{text: 'Take me to Home Page', to: HOME}}
    />
  </ErrorContainer>
)

export default AccessDeniedPage;