import React from 'react';
import { Flex } from 'rebass';
import styled from 'styled-components';
import { close } from '../../assets/icons';
import GetApp from '../../assets/images/download-app.svg';
import { Card, Image, Icon, CardTitle, CardText, Wrapper } from '../atoms';
import { media } from '../../utils/mediaBreakpoints';
import AppDownloadButtonsGroup from './AppDownloadButtonsGroup';

const Label = styled(Wrapper)`
  display:block
  ${media.mobile`
    display:none;
  `}
`

const AppDownloadTile = ({header, closeModal, subTitle, className}) => {
  return(
    <Card width={['100%', "575px"]} height={['100%', '291px']} className={className}>
      {closeModal && <Flex flexDirection='row-reverse' ><Icon mr={5} mt={4} src={close} onClick={closeModal} pointer size='lg'/></Flex>}
      <Flex justifyContent='space-between' flexDirection={['column', 'row']} alignItems='center' px={[4, 0]} backgroundColor={['white', 'unset']}>
        <Label>
          <Image src={GetApp} width="241px" />
        </Label>
        <Flex flexDirection="column" px={5} mb={[5, 0]}>
          <Flex textAlign={['center', 'left']} flexDirection='column'> 
            {React.isValidElement(header) ? header :
              (header ? <CardTitle lineHeight={1.5} pb={0} mb={0} fontSize='lg' color="grey">{header}</CardTitle>: null)
            }
            {React.isValidElement(subTitle) ? subTitle :
              (subTitle ? <CardText pb={0} mb={0} color='grey'>{subTitle}</CardText> : null)
            }
          </Flex>
          &nbsp;
          <AppDownloadButtonsGroup/>
        </Flex>
      </Flex>
    </Card>
  )
}

AppDownloadTile.defaultProps = {
  subTitle: "App provides a better experience and more features!",
  header: "This feature is avaliable on mobile app only"
}

export default AppDownloadTile;