import React from 'react'
import { Flex } from 'rebass'
import { Text } from './typography'

const PharmacySavingDetailsCard = ({orderId, amount, discountText, mt, textColor, amountColor, fontSize, lineHeight, bold}) => {
  let text = orderId ? `Order ID ${orderId}` : `${discountText}`
  return (
    <Flex justifyContent='space-between' alignItems='center' mt={mt}>
      <Text color={textColor} fontSize={fontSize} lineHeight={lineHeight} bold={bold ? true : false}>{text}</Text>
      <Text color={amountColor} fontSize={fontSize} lineHeight={lineHeight} bold={bold ? true : false}>₹{amount}</Text>
    </Flex>
  )
}
export default PharmacySavingDetailsCard
