import React, {Suspense, useState} from 'react';
import { Modal, RadioButtonGroup } from '../../molecules';
import { Card, Button, Input } from '../../atoms';
import { Flex } from 'rebass';
import styled, { css } from 'styled-components';
import { Text } from '../../atoms/typography';
import { useUserDetails } from '../../../utils/customHooks';
import getOptions from '../../../utils/options';
import toast from '../../../utils/toast';
import HomeAPI from '../../../services/home.api';
import {checkValidDob, addErrorsToDob} from '../../../utils/common';
import CustomDatePicker from '../../atoms/CustomDatePicker';

const ErrorMsg = styled.p`
	color: ${props => props.theme.colors.danger};
	margin-top: 0px;
`

const ExtendedInput = styled(Input)`
    border: none;
    background-color: ${props => props.theme.colors.backgroundColor};
    display : flex;
    flex-grow : 2;
    letter-spacing: 0.8px;
    color: #4D6189;
    font-size: 16px;
    font-family: ProximaNova-Semibold;

  `

const ExtendedFlex = styled(Flex)`
  ${({disabled}) => disabled && css`
    pointer-events: none;
  `}
`

const DobAndGenderUpdate = ({isMust, customer, profileUpdateModal, closeProfileModal, postUpdate, otherFieldsRequired}) => {
  const gender = ['Male', 'Female', 'Non-binary']
  const { user, requireDataUpdation } = useUserDetails();
  const currentUser = customer ? customer : user
  const [state, setState] = useState({selectedGender: gender.findIndex((item) => item === currentUser?.gender ) , dob: currentUser?.date_of_birth, name:  currentUser?.name, relation: currentUser?.relation })
  const [loading, setLoading] = useState(false)


  const onUpdateGender = (index) => {
    setState({...state, selectedGender: index})
  }

  const onChange = (value) => {
    setState({...state, dob: value})
  }

  const updateField = (e) => {
    const { value } = e.currentTarget;
    const { inputtype } = e.currentTarget.dataset
    if( inputtype == 'name')
      setState({...state, name: value })
  }


  const saveCustomer = () => {
    setLoading(true)
    let options = getOptions(currentUser.customer_token)
    let index = state.name?.trim().indexOf(" ")
    let firstName = index == -1 ? state.name : state.name?.substring(0,index)
    let lastName = index === -1 ? "" : state.name?.substring(index+1)
    HomeAPI.updateCustomers({data: {customer: {date_of_birth: state.dob, first_name: firstName, last_name: lastName, gender: gender[state.selectedGender]}}, ...options}, onSuccess, onFailure)

  }

  const onSuccess = () => {
    requireDataUpdation(true)
    setLoading(false)
    closeProfileModal()
    if(postUpdate){
      postUpdate(customer)
    }
    toast.success('Your details successfully updated!')
  }

  const onFailure = () => {
    toast.error('Something unexpected happened .. please try again later')
  }

  const checkDisabled = () => {
    return ![state.dob ,state.selectedGender].includes(null) && !checkValidDob(state.dob) && [0,1,2].includes(state.selectedGender)
  }




  


  return (
    profileUpdateModal && <Modal closeModal={closeProfileModal} closeOnClickOverlay={!isMust} size='xxs' direction='bottom-top' textAlign = 'start' minHeight={{mobile: '10vh', tablet: '20vh', desktop: '30vh'}} visibleOn={{desktop : true, tablet: true, mobile: true}} type="modal-root">
      <Card width='100%' >
        <Flex flexDirection='column' ml={6} mr={6}>
          <Text bolder mt={6} mb={3} fontSize='lg' color='extraInformation'>Personal Info</Text>
          <Text fontSize='md' color='lightBlue' lineHeight='24px' alignItems='start'>Please share your Name,  Gender and Date of Birth</Text>
          { otherFieldsRequired && 
          <>
            <Text fontSize='sm' mt={5} letterSpacing='1.2px' color='subtitle' lineHeight='xl' bold> NAME </Text>
            <ExtendedInput mt={3} data-inputtype={'name'} value={state.name} placeholder="Enter Name" onChange={updateField} data-testid='enter-name' type={'text'} />
          </>}
          <Text fontSize='sm' mt={5} letterSpacing='1.2px' color='subtitle' lineHeight='xl' bold> GENDER </Text>
          <ExtendedFlex flexDirection='row'>
            <RadioButtonGroup disabled= {gender.includes(currentUser?.gender) ? true : false} justifyContent='space-between' flexWrap='no-wrap' selectedIndex={state.selectedGender} borderColor={'darkGreyNew'} defaultBgColor={'lightestGreyNew'} activeBgColor={'lighterBlueGrey'} checkedColor='blue' bold color='true' defaultColor='lightBlue' padding={'13px 7px'} onChange={(e)=>onUpdateGender(e)} minWidth={'110px'} width={'33%'} minHeight={'46px'} list={[{title: 'Male'}, {title: 'Female'}, {title: 'Non-binary'}]} />
          </ExtendedFlex>
          <Text fontSize='sm' mt={5} letterSpacing='1.2px' color='subtitle' lineHeight='xl' bold>DATE OF BIRTH</Text>
          <Suspense fallback={'Loading'}>
            <CustomDatePicker disabled={currentUser.date_of_birth ? true : false} showYearDropdown={true} showMonthDropdown={true} selectedDob={state.dob} onChange={onChange} dob={true}/>
          </Suspense>
          { otherFieldsRequired && 
          <>
            <Text fontSize='sm' mt={5} letterSpacing='1.2px' color='subtitle' lineHeight='xl' bold> RELATIONSHIP </Text>
            <ExtendedInput mt={3} data-inputtype={'relation'} disabled={true} value={state.relation} data-testid='enter-relation' type={'text'} />
          </>}
          <ErrorMsg>{addErrorsToDob(state.dob)}</ErrorMsg>
          { checkDisabled() ?
            <Button type='primary' color={'blue'} disabled={loading && false} fontSize='md' bold letterSpacing='1.2px' onClick={saveCustomer} mt={6} ml={0} minHeight='56px' mb={5}>{ loading ? 'SUBMITING' : 'SUBMIT'}</Button>
            :
            <Button type='stateless' color={'blue'} disabled={true} fontSize='md' bold letterSpacing='1.2px' onClick={saveCustomer} mt={6} ml={0} minHeight='56px' mb={5}>Update Details</Button>
          }
        </Flex>
      </Card>
    </Modal> 
  )
}

DobAndGenderUpdate.defaultProps = {
  isMust: true,
  otherFieldsRequired: false
}

export default DobAndGenderUpdate;