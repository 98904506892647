
/* Using react-motion for drawer */

import React, { useState, useEffect } from 'react';
import Portals from '../../utils/portals';
import { Motion, spring } from 'react-motion';
import styled, {css} from 'styled-components';
import { media } from '../../utils/mediaBreakpoints';
import { animationDefaultConfig } from '../../utils/common';

const ModalContainer = styled.div`
  position: fixed;
  width: 532px;
  max-width: 532px;
  height: 100%;
  display: block;
  top: 0px;
  right: 0;
  overflow: auto; 
  background-color:${props => ({theme: {colors}}) => colors[props.backgroundColor]};
  padding: 0px;
  margin: 0px;
  border: 0px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px -1px 10px;
  z-index: ${props => props.zIndex || 100};
  ${media.mobile`
    width: 100%;
    max-width: 100%;
  `}
  &::-webkit-scrollbar{
    display: none;
  }
`

const ModalOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${props => props.zIndex || 20};
  ${({props}) => props.overLayBackgroundColor && css`
    background-color: rgba(0, 0, 0, 0.65);
  `}
  display: ${props => props.webDisplay || props.tabDisplay};
`

const Drawer = ({
  children, closeDrawer,
  closeOnClickOverlay,
  backgroundColor,
  open, springAnimation, visibleOn, ...props }) => {
  const willLeave = () => {
    return springAnimation ? {x: spring(0, animationDefaultConfig), opacity: spring(1, animationDefaultConfig)} : {x: spring(0), opacity: spring(1)}
  }

  const onClose = () => {
    updatePresentStyle(onUnmount())
    setTimeout(() => {
      closeDrawer()
    }, 100)
  }
  useEffect(() => {
    if(open) {
      updatePresentStyle(willLeave())
    } else {
      onClose()
    }
  }, [open])

  const [presentStyle, updatePresentStyle] = useState(willLeave())

  const onUnmount = () => springAnimation ? ({x: spring(500, animationDefaultConfig), opacity: spring(0, animationDefaultConfig)}) :
    ({x: spring(500), opacity: spring(0)})

  return (
    <Portals type='drawer' visibleOn={visibleOn}>
      {open ? <ModalOverlay props={props} zIndex={props.zIndex} onClick={closeOnClickOverlay ? onClose : null}/> : null}
      <Motion
        defaultStyle={{x: 500, opacity: 0}}
        style={presentStyle}
        willLeave={willLeave}>
        {(style) => (<ModalContainer backgroundColor={backgroundColor} textAlign={props.textAlign} style={
          {
            opacity: style.opacity,
            transform: `translateX(${style.x}px)`,
            WebkitTransform: `translateX(${style.x}px)`
          }
        } {...props}>
          {children}</ModalContainer>
        )}
      </Motion>
    </Portals>)
}

Drawer.defaultProps = {
  closeOnClickOverlay: false,
  open: false,
  backgroundColor: 'white',
  springAnimation: false,
  overLayBackgroundColor: true

}

export default Drawer;