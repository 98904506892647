import React from 'react';
import styled from 'styled-components';
import { Card, CardTitle, Image } from '../../atoms';
import { media } from '../../../utils/mediaBreakpoints';
import AddedMember from '../../../assets/images/added-member.svg';
import { Flex, Box} from 'rebass';
import BackIcon from '../../../assets/icons/back.svg';

const Wrapper = styled.div`
	text-align: ${props => props.textAlign};
	position: relative;
	min-height: 500px;
	${media.mobile`
		min-height: 100%;
	`}
`

const AlignLeft = styled.div`
	text-align: start;
`

const AlreadyAdded = ({relation, goBack, discardAddMember}) => {
	
  return(
    <Card px='20px' py='20px' width='100%'>
      <Wrapper textAlign='center'>
        <Flex width='100%' flexWrap='wrap' justifyContent='center' >
          <Box width={['50%']}>
            <AlignLeft><Image alignSelf='right' src={BackIcon} width='20px' onClick={goBack} /></AlignLeft>
          </Box>
          <Box width={['50%']}>
            <CardTitle alignSelf='right' onClick={discardAddMember}>X</CardTitle>
          </Box>
          <Image src={AddedMember} />
          <CardTitle fontSize='lg' mt='30px' pb='3px' color='green'>{relation} already added</CardTitle>
        </Flex>
      </Wrapper>
    </Card>
  )	

}

export default AlreadyAdded;