import React, { useEffect, useReducer, useContext } from 'react';
import styled, { css, withTheme } from 'styled-components';
import { Navbar as NavbarStyle } from '../../molecules';
import { withRouter } from 'react-router-dom'
import { AnimatedNavbar } from '../common';
import NavGroup from '../../atoms/NavGroup';
import { media } from '../../../utils/mediaBreakpoints';
import { Flex } from 'rebass';
import { NavbarContext } from '../../../utils/contexts';
import HomeAPI from '../../../services/home.api';
import { DASHBOARD } from '../../../routes/private.js';
import { withErrorBoundaryHOC } from '../../../utils/hocs'
import NewFreshChat from '../../../utils/NewFreshChat';
import NameBanner from '../dashboard/NameBanner';
import ToggleVisibility from '../../atoms/ToggleVisibility';
import useUserDetails from '../../../utils/customHooks/useUserDetails';
import { ekincareLogo } from '../../../utils/constants';

export const ExtendedFlex = styled(Flex)`
  ${({ hideNavbar }) => hideNavbar && css`
  display: none;
`}
`

export const NavLogo = styled.div`
  max-width: 107px;
  max-height: 45px;
`
export const NavbarItem = styled.div`
  padding: 20px 10px;
  cursor: pointer;
  font-family: ProximaNova-Semibold;
  color: ${props => props.theme.colors.darkestGrey};
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  align-items: center;
`
export const IconContainer = styled.div`
  height: 100%;
  margin: auto 10px;
  border-radius: 50%;
  position: relative;
`

export const ChatWithUsWrapper = styled.div`
  &:hover {
    background-color: ${props => props.theme.colors.bodyHoverColor};
    border-radius: 5px;
  }
  padding: 8px 12px;
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
`
export const ChatWithUsText = styled.span`
  ${media.tablet`
    display: none;
  `}
  font-family: ${props => props.fontFamily || 'ProximaNova-Semibold'};
`

export const DropdownMenu = styled.div`
  min-width: 350px;
  min-height: 180px;
  background-color: ${props => props.theme.colors.white};
  padding: 20px 0 0;
  position: absolute;
  z-index: 1000;
  right: -30px;
  top: 45px;
  border-radius: 5px;
  box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.15);
  &:after {
    content:"";
    position: absolute;
    right: 30px;
    top: -10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #ffffff transparent;
    z-index:9999;
  }
`
export const ProfileContainer = styled(Flex)`
  position: relative;
  &:after {
    position: absolute;
    content: '';
    width: calc(100% + 20px);
    height: 1px;
    bottom: 0px;
    left: -10px;
    background-color: ${({ theme: { colors } }) => colors.border};
  }
`
export const LogoutContainer = styled(Flex)`
  position: relative;
  &:before {
    position: absolute;
    content: '';
    width: calc(100% + 20px);
    height: 1px;
    top: 0px;
    left: -10px;
    background-color: ${({ theme: { colors } }) => colors.border};
  }
`

export const FlexWithHoverEffect = styled(Flex)`
  position: relative;
  padding-top: 1.5%;
  padding-bottom: 1.3%;
  &:hover {
    background-color: ${({ theme: { colors } }) => colors.bodyHoverColor};
  }
`

const EWAP_URL = 'EWAP_URL';
const reducer = (state, action) => {
  switch (action.type) {
  case EWAP_URL: {
    return { ...state, benefits: { ...state.benefits, ewapUrl: action.url } }
  } default: {
    return state;
  }
  }
}

const Navbar = ({ history, hideNavbar }) => {  
  const { data } = useContext(NavbarContext)
  const { partner_logo, company_logo } = useUserDetails();
  const logo_src = partner_logo || company_logo || ekincareLogo;
  // eslint-disable-next-line
  const [ _, dispatchData ] = useReducer(reducer, {
    notifications: {}
  })

  useEffect(() => {
    if (data.benefits && data.benefits.company_policy && data.benefits.company_policy.enable_ewap && !data.benefits.ewapUrl) {
      HomeAPI.fetchEwapUrl({}, (response) => {
        dispatchData({ type: EWAP_URL, url: response.data.url })
      }, () => { })
    }
  }, [data.benefits])

  return (
    <ExtendedFlex hideNavbar={hideNavbar}>
      <NavbarContext.Provider value={data}>
        <NavbarStyle as="nav">
          <NavGroup p='0px' ml='92px' width={'107px'} key={1}>
            {partner_logo !== '' && <NavLogo as="img" src={logo_src} onClick={() => history.push(DASHBOARD)} />}
          </NavGroup>
          <NavGroup style={{ width: '65%', padding: '0px', paddingRight: '52px' }} key={1}>
            <AnimatedNavbar history={history} />
            <ToggleVisibility visibleOn={{mobile: false, desktop: true, tablet: true}}>
              <NameBanner/>
            </ToggleVisibility>
          </NavGroup>
          <NavGroup key={3}>
            <NewFreshChat type={'header'}/>
          </NavGroup>
        </NavbarStyle>
      </NavbarContext.Provider>
    </ExtendedFlex>
  )
}

export default withRouter(withTheme(withErrorBoundaryHOC(Navbar)));
