import React, { Fragment } from 'react';
import { CardTitle, CardText } from '../atoms';

const HeaderWithDescription = ({mb, mt, header, description, descriptionSize, headerSize, descriptionColor, descriptionBold}) => {
  return(
    <Fragment>
      <CardTitle mt={mt || undefined} fontSize={headerSize} mb='0px' pb='0px'>{header}</CardTitle>
      <CardText fontFamily={descriptionBold && 'Semibold' } color={descriptionColor} mb={mb || undefined} fontSize={descriptionSize}>{description}</CardText>
    </Fragment>	
  )
}

HeaderWithDescription.defaultProps = {
  descriptionSize: 'sm',
  headerSize: 'md',
  descriptionBold: false,
  descriptionColor: 'body'
}

export default HeaderWithDescription;