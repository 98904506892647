import React from 'react';
import styled from 'styled-components';
import { downBlack, downWhite } from '../../assets/icons';
import Icon from './Icon';

const HalfCircleUp = styled.button`
  background-color: ${props => props.backgroundColor || '#9e978e'};
  border: 1px solid ${props => props.backgroundColor || '#9e978e'};
  height: 50px;
  width: 100px;
  border-bottom-left-radius: 110px;
  border-bottom-right-radius: 110px;
  cursor: pointer;
`

const Span = styled.div`
  font-size: ${props => props.theme.fontSizes['xs']};
  color: ${props => props.theme.colors[props.color]};
`

const ExtendedIcon = styled(Icon)`
  margin-top: -10px;
`

const ShowLess = ({textColor, onShowLess, ...props }) => {
  let icon = textColor === 'white' ? downWhite : downBlack
  return(
    <HalfCircleUp backgroundColor={props.backgroundColor} onClick={onShowLess}>
      <Span color={textColor}>Show Less</Span>
      <ExtendedIcon src={icon} size='xxl' />
    </HalfCircleUp>
  )
}

ShowLess.defaultProps = {
  textColor: 'white'
}

export default ShowLess