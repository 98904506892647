import React, { Fragment, forwardRef } from "react";
import styled, { css } from "styled-components";
import Icon from "./Icon";
import { Text } from "./typography";

const Wrapper = styled.div`
  ${({lineHeight}) => lineHeight && css`line-height: ${lineHeight}`};
  ${({pl}) => pl && css`padding-left: ${pl}`};
  ${props => {
  return props.width && css`
      width: ${props => props.width};
    `
}};
  ${({ flex }) => flex && css`
    position: relative;
    display: flex;
    flex-direction: column;
  `};
`;

const Label = styled.label`
  float: left;
  margin-right: ${props => props.mr};
  margin-bottom: ${({ mb }) => mb || '5px'};
  min-width:  ${({ minWidth }) => (minWidth)};
  min-height:  ${({ minHeight }) => (minHeight)};
  background-color: ${({ theme: { colors }, checked, activeBgColor, defaultBgColor }) => checked ? colors[activeBgColor] : colors[defaultBgColor]};
  border-radius: ${props => props.br || '4px'};
  border: ${props => props.borderColor ? ('1px solid ' + props.theme.colors[props.borderColor]) : ('1px solid ' + props.theme.colors.blue)};

  ${({ borderWidth }) => borderWidth && css`border-width: ${borderWidth}`};
  color: ${({ theme: { colors }, checked, checkedColor, defaultColor }) => (checked ? colors[checkedColor] : colors[defaultColor])};
  ${props => {
  return props.color && css`
      color: ${({ theme: { colors }, checked, checkedColor, dafaultColor }) => (checked ? colors[checkedColor] : colors[dafaultColor])};
    `
}}
  font-family: ${({ theme: { fonts } }) => fonts.semiBold};
  overflow: auto;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme: { colors }, activeBgColor }) => activeBgColor ? colors[activeBgColor] : colors.blue};
    color: ${({ theme: { colors }, checkedColor }) => colors[checkedColor]};
    border: ${props => !props.activeBgColor ? ('1px solid ' + props.theme.colors[props.borderColor]) : ('1px solid ' + props.theme.colors.blue)};
    ${({ borderWidth }) => borderWidth && css`border-width: ${borderWidth}`};

  }
  ${({ disabled }) => disabled && css`
    pointer-events: none;
  `}

  ${props => {
  return props.br && css`
      border-radius: ${props => props.br}; 
    `
}}
  ${props => {
  return props.checked && css`
      border-radius: ${props => props.br}; 
      background-color: ${({ theme: { colors }, activeBgColor }) => activeBgColor ? colors[activeBgColor] : colors.blue};
      color: ${({ theme: { colors }, checked, checkedColor, dafaultColor }) => (checked ? colors[checkedColor] : colors[dafaultColor])};
      border: ${props => props.activeBgColor ? ('1px solid ' + props.theme.colors.blue) : (props.borderColor ? ('1px solid ' + props.theme.colors[props.borderColor]) : ('2px solid ' + props.theme.colors.blue))};
    `
}}
`;

const RadioBtn = styled.input.attrs({
  type: "radio",
  name: "toggle"
})`
  position: absolute;
  top: -20px;
  display: none;
`;

const Span = styled.span`
  text-align: ${props => props.textAlign};
  font-size: ${props => props.theme.fontSizes[props.fontSize]};
  padding:  ${props => props.padding};
  display: block;
  font-family: ${props => props.fontFamily ?? "ProximaNova-Semibold"};
  ${({ mt }) => mt && css`margin-top: ${mt}`};
`;

const Tag = styled(Text)`
  letter-spacing: 1px;
  border-radius: 3px;
  position: absolute;
  padding: 3px 8px;
  bottom: -6%;
  left: ${props => props.left ?? '8%'};
  ${({ theme: { tags: { newYellow: { color, backgroundColor } } } }) => css`
  color: ${color};
  background-color: ${backgroundColor}
   `};
`

const Radio = ({ disabled, onChange, onClick, checked, borderColor, testid, component, minWidth, minHeight, size, showIcon, textColor, index, addTag, radioIcons, borderWidth, fontWeight, lineHeight, pl, pt, left, tagFontSize, itemRef, ...props }) => {
  return (
    <Wrapper ref={itemRef} width={props.width} lineHeight={lineHeight} pl={pl} pt={pt} flex={addTag?.idx === index}>
      {component ?
        <Fragment>
          <RadioBtn onChange={onChange} />
          {component}
        </Fragment>
        :
        <Label disabled={disabled} {...props} borderWidth={borderWidth} borderColor={borderColor} minHeight={minHeight} defaultBgColor={props.defaultBgColor} activeBgColor={props.activeBgColor} textColor={textColor} minWidth={minWidth} checked={checked}>
          <RadioBtn data-testid={testid} onChange={onChange} onClick={onClick} />
          <Span textAlign={showIcon ? 'start' : 'center'} fontSize={props.fontSize} fontWeight={fontWeight} fontFamily={props.fontFamily} lineHeight={props.lineHeight} padding={props.padding} mt={props.mt}>
            {/* if you need icon with radio button please use showIcon flag */}
            {/* radioIcons = [radioUnselectIcon, radioSelectedIcon] */}
            {showIcon && <Icon size={size} mr={'12px'} mt={props.iconMt} src={checked ? radioIcons[1] : radioIcons[0]}></Icon>}
            {props.children}
          </Span>
        </Label>
      }
      {addTag?.idx === index && addTag?.text && <Tag lineHeight='14px' fontSize={tagFontSize ?? 'sm'} left={left} bold>{addTag.text}</Tag>}
    </Wrapper>
  );

};

Radio.defaultProps = {
  testid: 'radio',
  minHeight: '40px',
  defaultBgColor: 'white',
  activeBgColor: 'blue',
  defaultColor: 'body',
  checkedColor: 'white',
  br: '4px'
}

export default forwardRef(Radio);
