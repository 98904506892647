import React from 'react';
import { Flex, Box } from 'rebass';
import { Image, CardTitle, Button } from '../components/atoms';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Text } from '../components/atoms/typography';
import Uploader from '../components/molecules/Uploader';
import {PageHeader} from '../components/molecules/buildingblocks';
import { isMobile } from '../utils/common';


const ExtendedImg = styled(Image)`
  min-width: 300px;
  min-height: 200px;
`
const UploaderWrapper = styled(Box)`
  max-width: 200px;
  text-align: center;
  `

const EmptyPage = ({ image, header, body, clickToAction, dataTestId, eventName, uploadButton, noMargin, headerBar, pl, height='80vh', ...props }) => (
  <Flex flexDirection='column'>
    {
      headerBar && 
      <PageHeader title={headerBar?.text} onClick={headerBar.onClick} fontSize={'lg'} iconNeeded={isMobile()} modal={false} pl={pl}/>
    }
    {uploadButton ? 
      <>
        <Flex flexDirection='column' mx={'48px'} alignItems='center' justifyContent='center' mt='20px' mb='20px'>
          { image ? <ExtendedImg src={image.src} width={'30%'}/> : null } 
          { header ? <Text textAlign='center' fontSize={['mdL', 'xl']} mt={6} color='extraInformation' letterSpacing='0px' bolder>{header.text}</Text> : null }
          { body ? <Text mt={'12px'} width={['100%', '80%']} lineHeight='22px' color='lightBlue' fontSize={'md'} textAlign='center'>{body.text}</Text> : null }
          <UploaderWrapper mt={'32px'}>
            <Uploader background={'primary'} isButton={true} btnSize='mdL' br={'3px'} btnType='primary' isAssigned={false} customerToken={props.customerToken} updateFiles={props.updateFiles} type={props.type} onSuccess={props.onFileUploadSuccess} onFailure={props.onFileUploadFailure}>
              <CardTitle mb='0px' pb='0px' bold color='white' data-event-name='mr_add_reports' data-event-data={JSON.stringify({'statux':'gallery'})} fontSize='md'>{props.btnText}</CardTitle>
            </Uploader>
          </UploaderWrapper>
        </Flex>
      </>
      :
      <Flex flexDirection='column' mx={'48px'} alignItems='center' justifyContent='center' height={height} data-testid={dataTestId}>
        {image ? <ExtendedImg src={image.src} height={'auto'} width={image.width ? image.width : ['80%', '500px']}/> : null}
        {header ? <Text fontSize={['mdL', 'xl']} textAlign='center' mt={noMargin ? -5 : 6 } color='extraInformation' letterSpacing='0px' bolder>{header.text}</Text> : null}
        {body ?
          <>
            <Text justifyContent='center' mt={['8px', '12px']} width={body?.width ? body.width : ['100%', '110%']} lineHeight='24px' color='lightBlue' fontSize={'md'} textAlign='center'>{body.text}</Text>
            {body.subText ? <Text justifyContent='center' mt={5} width={['100%', '80%']} lineHeight='24px' color='lightBlue' fontSize={'md'} textAlign='center'>{body.subText}</Text> : null }
          </>
          : null}
        {clickToAction && !clickToAction.to && clickToAction.onClick ? <Button mt={6} onClick={clickToAction.onClick} size={'mdL'} fontSize='md' letterSpacing='0' br='6px' data-event-name={eventName} type='primary'>{clickToAction.text}</Button> : null}
        {clickToAction && !clickToAction.onClick && clickToAction.to ? <Button mt={6} to={clickToAction.to} as={Link} size={'mdL'} fontSize='md' letterSpacing='0' br='6px' data-event-name={eventName} type='primary'>{clickToAction.text}</Button> : null}
      </Flex>}
  </Flex>
)

export default EmptyPage;