import React, { useContext } from 'react';
import { Modal } from '../../molecules';
import { ModalContext } from '../../../utils/contexts';
import AddFamilyMembers from '../add-family-members/AddFamilyMembers';

const AddFamilyMember = () => {
  const showModal = useContext(ModalContext)
  
  const onCloseModal = () => {
    showModal({addFamilyMember: false})
  }
  return (
    <Modal size='md' closeModal={onCloseModal} type="modal-root">
      <AddFamilyMembers closeModal={onCloseModal}/>
    </Modal>
  )
}

export default AddFamilyMember;