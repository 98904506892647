/**
* This is the loader used with code splitted components.
*/
import React from 'react';
import styled ,{keyframes} from 'styled-components';

const Container = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 60px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 3px;
  background-color: rgb(255, 255, 255);
`
const InitialLoadAnimation = styled.div`
  position: relative;
  top: 40vh;
  width: 200px;
  margin: 0 auto;
  transform: scale(1);
  transition: transform .5s ease;
`

const LoadingBar = styled.div`
  width: 50px;
  height: 50px;
  margin: auto;
  border: solid 5px #0951e1;
  border-radius: 50%;
  border-right-color: transparent;
  border-bottom-color: transparent;
  transition: all 0.5s ease-in;

  animation: ${keyframes`
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  `} 1.0s infinite linear;

  -webkit-animation: ${keyframes`
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  `} 1.0s infinite linear;

`
const SuspenseLoader = () => {
  return (
    <Container>
      <InitialLoadAnimation>
        <LoadingBar />
      </InitialLoadAnimation>
    </Container>
  )
}

export default SuspenseLoader;