import React from 'react'
import { Card } from 'rebass'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

const ExtendedCard = styled(Card)`
  ${({ borderRequired }) => borderRequired === true && css`border: 1.5px solid ${props => props.theme.colors[props.borderColor]};`}
  border-radius: ${props => props.borderRadius};
  background-color: ${props => props.theme.colors[props.backgroundColor]};
  height: ${props => props.height};
  margin-left: ${props => props.ml};
  margin-right: ${props => props.mr};
  margin-top: ${props => props.mt};
  margin-bottom: ${props => props.mb};
  overflow: scroll;
  margin-top: 12px;
  text-align: ${props => props.textAlign};
  ::-webkit-scrollbar {
    width: 5px;
    height: 0px;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`

const CardWithScroll = ({borderRequired, borderColor, backgroundColor, height, borderRadius, children, mb, mt, ml, mr, textAlign}) => {
  return (
    <ExtendedCard textAlign={textAlign} borderRequired={borderRequired} borderColor={borderColor} backgroundColor={backgroundColor} height={height} borderRadius={borderRadius} mb={mb} mr={mr} mt={mt} ml={ml}>
      {children}
    </ExtendedCard>
  )
}

CardWithScroll.propTypes = {
  borderRequired: PropTypes.bool
}

export default CardWithScroll