import React, { useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Route, Switch } from 'react-router-dom';
import { publicRoutes, PRIVATE_ROUTES } from './routes';
import Layout from './pages/Layout';
import { withContextHOC, withTrackerHOC } from './utils/hocs';
import { isMobile } from './utils/common';
import RedirectToApp from './components/organisms/RedirectToApp';
import NotFoundPage from './pages/errors/NotFoundPage';
import tracker from './utils/tracker';
import { queryParams } from './utils/common';
import useAppType from './utils/customHooks/useAppType';

const App = () => {
  const appType = useAppType();

  useEffect(() => {
    // eslint-disable-next-line valid-typeof
    if (typeof window.Moengage !== undefined) {
      trackEvent()
    }
  }, [])

  const trackEvent = useCallback(() => {
    const params = queryParams(window.location.search)
    const moengageEvents = {}
    for (const [key, value] of Object.entries(params)) {
      if (key.startsWith('utm')) {
        moengageEvents[key] = value
      }
    }
    if (Object.keys(moengageEvents).length > 0) {
      tracker.track('utm_campaign', moengageEvents)
    }
  })
  return (
    <Container>
      <Switch>
        {(!window.isEkincareApp() && isMobile() && appType === 'NORMAL') && <RedirectToApp />}
        {publicRoutes()}
        <Route path={PRIVATE_ROUTES.reduce((acc, { path }) => acc.concat(path), [])} component={Layout} exact={true} />{/* ets a navbar, bottom and placeholder for signed in users.*/}
        <Route component={NotFoundPage} />{/* Renders when no routes are found. */}
      </Switch>
    </Container>)
}

const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  max-width: 100vw;
  max-height: 100vh;
  flex-direction: column;
`

export default withTrackerHOC(withContextHOC(App));
