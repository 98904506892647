import React from 'react';
import { ListCell } from '../../molecules';
import { withTheme } from 'styled-components';
import { Card, CardTitle } from '../../atoms';

/**
* theme is passed from styled component withTheme HOC.
*/
const ListsWithHeader = ({ theme, listCellOptions, header, mt, textMargin, subTextmargin, padding }) => {
  const listCard = () => (
    <Card mt={mt} borderRequired={true}>
      {listCellOptions.map(({ header, subHeader, onClick, icon, link, type, direction=true }, i) => {
        if (type==='action') {
          return <ListCell onClick={onClick} border={`0.1px solid ${theme.colors.borderColor}`} key={i} header={header} subHeader={subHeader} icon={icon} direction={direction} padding={padding}/>
        }
        return <ListCell textMargin={textMargin} subTextmargin={subTextmargin} border={`0.1px solid ${theme.colors.borderColor}`} key={i} header={header} subHeader={subHeader} icon={icon} link={link} direction={direction} padding={padding}/>
      })}
    </Card>
  )
  if(header.text) {
    return (
      <Card borderRequired={false} >
        {header.text && <CardTitle pl={'10px'} py='5px' backgroundColor={theme.colors.lightestGrey} mb='0'>{header.text}</CardTitle>}
        {listCard()}
      </Card>)
  }
  return(listCard())
}

ListsWithHeader.defaultProps = {
  listCellOptions: [],
  header: {}
}

export default withTheme(ListsWithHeader);