import React from "react";
import styled, { css } from "styled-components";
import { Box } from 'rebass';

// TODO:: Move this to Icon styled-components atom folder.
const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

const checkedStyles = css`
  visibility: visible;
`;

const unCheckedStyles = css`
  visibility: hidden;
`;

const HiddenCheckbox = styled.input.attrs({
  type: "checkbox"
})`
  border: 0;
  clip-path: border-box;
  overflow: hidden;
  padding: 0;
  opacity: 0;
  position: absolute;
  white-space: nowrap;
`;

const SIZES = {
  'xs': 12,
  'sm': 14,
  'md': 16,
  'lg': 18,
  'xl': 20,
}

const setCheckboxColor = ({variant, theme: { colors }}) => {
  return colors[`${variant}`] || colors.primary;
}

const StyledCheckbox = styled.div.attrs(({ size = 'md'}) => {
  const modifiedSize = typeof(size) === 'number' ? size : (SIZES[size] || 16);
  return {
    width: modifiedSize,
    height: modifiedSize,
    type: 'checkbox'
  }
})`
  display: inline-block;
  /* cursor: pointer; */
  width: ${props => `${props.width}px`};
  height: ${props => `${props.height}px`};
  background: ${(props) => props.checked ? setCheckboxColor(props) : 'none'};
  border: 2px solid ${(props) => setCheckboxColor(props)};
  border-radius: 3px;
  transition: all 150ms;
  ${HiddenCheckbox}:focus + &{
    box-shadow: 0 0 0 3px ${(props) => setCheckboxColor(props)};
  }
  ${Icon} {
    ${props => (props.checked ? checkedStyles : unCheckedStyles)}
  }
`;

const CheckboxContainer = styled(Box)`
  display: inline-block;
  vertical-align: middle;
  position: relative;
  pointer-events: ${(props) => props.pointerEvents ? 'none' : 'inherit' };

`;

/**
 * size has parameters: [xs, sm, md, lg, xl] refer above for more.
 * checked: parameter defined to say whether checkbox is checked or not.
 * onClick event handler to handle checkbox variant on click.
 * className argument is for styled-components to give dynamic classNames to the child elements.
 */
const Checkbox = ({ pointerEvents, checked, onClick, size, ...props }) => {
  const { variant, className } = props;
  return (
    <CheckboxContainer pointerEvents={pointerEvents} className={className} {...props}>
      <HiddenCheckbox checked={checked} {...props} data-testid='checkbox' onClick={onClick} readonly/>
      <StyledCheckbox checked={checked} size={size} variant={variant}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>)
};
export default Checkbox;
