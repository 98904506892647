import React from 'react'
import { Box, Card } from 'rebass'
import Icon from './Icon'
import { Text } from './typography'
import styled, { css } from 'styled-components'

const ExtendedCard = styled(Card)`
  transition: transform .3s;
  cursor: pointer;
  ${(props) =>props.hoverEffect &&
    css`
      &:hover {
        transform: scale(1.05);
      }
    `}
`

const CardWithTitleDescriptionIcon = ({ width, icon, title, description, mr, mt, onClick, mb,hasShadow=true, hasBorderRadius=true,px = '18px', py = '18px',backgroundColor = 'white',fontSize = 'md',hoverEffect=true, iconSize = 'xxxml' }) => {
  return (
    <ExtendedCard onClick={onClick} mr={mr} mt={mt} width={width} display={'flex'} backgroundColor={backgroundColor} px={px} py={py} boxShadow={hasShadow ? '0px 1px 8px rgba(0, 0, 0, 0.1)' : 'none'} borderRadius={hasBorderRadius ? '10px' : '0'} mb={mb} hoverEffect={hoverEffect}>
      <Icon src={icon} size={iconSize}></Icon>
      <Box ml={'12px'} alignItems={'center'}>
        <Text fontSize={fontSize} bolder bold color={'extraInformation'} lineHeight="20px" mb='6px'>{title}</Text>
        {description.length > 0 &&
      <Text bold fontSize='sm' normal color={'lightGrey'} lineHeight="18px">{description}</Text>
        }
       
      </Box>
    </ExtendedCard>
  )
}

export default CardWithTitleDescriptionIcon