import styled, { css } from 'styled-components';
import { Flex } from 'rebass';

// Tabbar accomodates vertical and horizontal tabs.
// use inline display to display a viewable cards aside vertical tabbar.

const Tabbar = styled(Flex).attrs(({ verticalAlign = null }) => ({
  as: 'ul',
  verticalAlign
}))`
  position: relative;
  background-color: ${({ backgroundColor }) => backgroundColor || 'white'};
  margin: 10px 0px;
  list-style: none;
  padding: ${props => props.padding || '10px'};
  overflow-x: auto;
  &:hover {
      color: ${({ theme: { colors }}) => colors.primary};
  }
  ${({ verticalAlign }) => verticalAlign && css`
    display: table;
  `}
  ${({ borderRequired }) => borderRequired && css`
    border: 1px solid ${({ theme: { colors }}) => colors.border};
  `}
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);
`

export default Tabbar;