import styled, { css } from 'styled-components';
import themes from '../../themes';
import { Flex } from 'rebass';

const variantStyle = (variant,mt) => {
  switch(variant){
  case 'primary':
    return css`
      color: ${themes.colors.white};
      background-color: ${themes.colors.primary};
    `
  case 'missed':
    return css`
      color: ${themes.colors.white};
      background-color: ${themes.colors.lightGrey};
    `
  case 'secondary':
    return css`
      color: ${themes.colors.white};
      background-color: ${themes.colors.secondary};
    `
  case 'success':
    return css`
      color: ${themes.colors.darkestGreen};
      background-color: ${themes.colors.lightestGreen};
    `
  case 'danger':
    return css`
      color: ${themes.colors.darkestRed};
      background-color: ${themes.colors.lightestRed};
    `
  case 'warning':
    return css`
      color: ${themes.colors.darkestYellow};
      background-color: ${themes.colors.lightestYellow};
    `
  case 'info':
    return css`
      color: ${themes.colors.white};
      background-color: ${themes.colors.info};
    `
  case 'light':
    return css`
				color: #212529;
    		background-color: ${themes.colors.light};
      `
  case 'moderate':
    return css`
      color: #FF9800;
      background-color: #FFF8E4;
  `
  case 'status':
    return css`
      color:  ${themes.colors.extraDarkBlue};
      background-color: ${themes.colors.btnLighterSkyBlue};
      width: max-content;
      margin-top: ${mt};
  `
  case 'darkStatus':
    return css`
      color:  ${themes.colors.primary};
      background-color: ${themes.colors.lighterBlueGrey};
      width: max-content;
      margin-top: ${mt};
    `

  case 'moderateDark':
    return css`
      color: ${themes.colors.white};
      background-color: ${themes.colors.extraInformation};
  `
  case 'grey':
    return css`
      color: ${themes.colors.lightGrey};
      background-color: ${themes.colors.lightestGreyNew};
  `
  case 'green':
    return css`
      color: ${themes.colors.darkestGreen};
      background-color: ${themes.colors.lightestGreenNew};
  `
  case 'red':
    return css`
      color: ${themes.colors.darkRed};
      background-color: ${themes.colors.lightestRedNew};
  `

  case 'dark':
  default:
    return css`
      color: ${themes.colors.white};
      background-color: ${themes.colors.dark};
    `	
  }
}

/**
stickySide: represents the side onto which the badge should be sticked to.
Ex: sticktSide: right, the border-radius on left-bottom, left-top are rounded.
*/
const stickySideRadius = (stickySide) => {
  if(!stickySide) return null;
  switch(stickySide) {
  case 'right': {
    return css`
      border-bottom-left-radius: 1rem;
      border-top-left-radius: 1rem;
    `
  } case 'left': {
    return css`
      border-bottom-right-radius: 1rem;
      border-top-right-radius: 1rem;
    `
  } case 'top': {
    return css`
      border-bottom-right-radius: 1rem;
      border-bottom-left-radius: 1rem;
    `
  }
  case 'bottom': {
    return css`
      border-top-right-radius: 1rem;
      border-top-left-radius: 1rem;
    `
  }
  }
}

const Badge = styled(Flex).attrs(({ stickySide, borderRadius, padding }) => ({as: 'span', stickySide, borderRadius, padding}))`
display: inline-flex;
padding: ${({padding}) => padding ? padding : '.5em .7em .5em .7em'}; 
font-size: ${({fontSize}) => fontSize ? fontSize : '80%'};
line-height: 1;
text-align: center;
white-space: nowrap;
vertical-align: baseline;
font-family: ${({ bolder, bold, theme: { fonts }}) => {
  if(bolder) {
    return fonts.extraBold
  } else if(bold) {
    return fonts.semiBold
  } else {
    return fonts.normal
  }
}};
${({ stickySide }) => {
  if(stickySide) {
    return stickySideRadius(stickySide)
  } else {
    return css`
      border-radius: 12px;
    `
  }
}}
${({ variant,mt="0px" }) => variantStyle(variant,mt)}
`
export default Badge