import styled from 'styled-components';
import React from 'react';


const PopoverBody = styled.div`
	padding: 2px;
`

const PopoverWrapper = styled.div`
	position: fixed;
  border: 2px solid #dedede;
  background-color: white;
	top: ${props => props.styles.y}px;
	left: ${props => props.styles.x}px;
`
// Todo: Add more positioning options and add arrow
const Popover = ({children, target, ...props}) => {
  const rect = target.getBoundingClientRect()
  const styles = {x: rect.left + rect.width, y: rect.top}
  return(
    <PopoverWrapper styles={styles} {...props}>
      <PopoverBody>{children}</PopoverBody>
    </PopoverWrapper>
  )

}	

export default Popover
