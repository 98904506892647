import React, {useState} from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'rebass';
import HomeAPI from '../../services/home.api';
import { CardText, CardTitle, SectionHeading, Icon } from '../atoms';
import { deleteRed } from '../../assets/icons';
import getOptions from '../../utils/options';
import toast from '../../utils/toast';

const Wrapper = styled.div`
display: ${props => props.display};
margin-top: ${props => props.mt}px;
text-align: ${props => props.textAlign};
`

const Label = styled.label`
border-style: dashed;
border-color: ${props => props.theme.borders.lighter};
padding: 55px 40px;
width: 100%;
`

const FileInput = styled.input.attrs({type: 'file', multiple: true, accept: ".jpg, .png, jpeg, .pdf"})`
display: none;
`
const ProgressBar = styled.progress`
width: 100%;
&:-webkit-progress-bar {
  background: lightgray;
}
&:-webkit-progress-value {
  background: #06c;
  transition: width 2.5s ease-in-out;
}
` 

const FileUploader = ({customer, type, onSuccess, onFailure, onDelete}) => {

  const [fileList, updateFileList] = useState([])

  const handleFileSelect = (e) => {
    const files = e.target.files || e.dataTransfer.files;
    const fileList = Object.keys(files).map(file => files[file]);
    updateFileList(fileList)
    window.Promise.all(Array.from(files).map((file, i) => {
      var data = new FormData();
      data.append('file', file);
      const config = {
        onUploadProgress: myUploadProgress(i)
      }
      return uploader(data, config);
    })).then((response) => {
      onSuccess(response.map(res => res.data))
    }).catch((error) => {
      updateFileList(fileList.filter(i => i.id))
      if(error?.response?.status === 422) {
        toast.error(`File cannot be uploaded.`)
      } else {
        toast.error(`File cannot be uploaded. please try again later.`)
      }
      onFailure(error)
    })
  }

  const uploader = (data, config) => {
    if (customer){
      let options = getOptions(customer.customer_token)
      return HomeAPI.fileUploadToServer({data: data, ...options, ...config}, type)
    }	else {
      return HomeAPI.fileUploadToServer({data: data, ...config}, type)
    }
  }

  const myUploadProgress = (fileIndex) => (progress) => {
    let percentage = Math.floor((progress.loaded * 100) / progress.total)
    let element = document.getElementById('fileprogressBar'+fileIndex)
    let element2 = document.getElementById('fileprogressPercentage'+fileIndex)
    let splitPercent = percentage/4
    let splitValues = [splitPercent, 2*splitPercent, 3*splitPercent, 4*splitPercent]
    splitValues.map((value) => {
      element.value = value
      element2.textContent = value+'%'
    })
  }  

  const removeItem = (index, name) => {
    onDelete(name)
    let newList = fileList.slice(0)
    newList.splice(index, 1)
    updateFileList(newList)
    if (newList.length === 0) {
      let ele = document.getElementById('fileUploadId')
      if (ele) {
        ele.value=''
      }
    }
  }

  const title = {
    "emr": "You can securely upload all medical documents",
    "edp": "You can securely upload all medical prescriptions",
    "emi": "You can securely upload all medical invoices"
  }

  return(
    <Wrapper textAlign={'center'}>
      <SectionHeading>{title[type]}</SectionHeading>
      <CardText>Note: You can only upload PNG, JPG, PDF</CardText>
      <Flex mt='30px' mb='20px'>
        <Label id='fileUpload'>
          <FileInput id='fileUploadId' onChange={(e)=>handleFileSelect(e)} accept='images/*' capture="camera" />
          Click to upload
        </Label>
      </Flex>
      {fileList && fileList.map((file, index) =>(
        <Wrapper key={index}>
          <Flex width='100%' flexWrap='wrap' mt='7px'>
            <Box width={['70%', '80%']}>
              <ProgressBar value='0' max='100' id={'fileprogressBar' + index}/>
            </Box>	
            <Box width={['20%', '15%']}>
              <CardTitle ml='10px' id={'fileprogressPercentage'+index} />
            </Box>	
            <Box width={['10%', '5%']}>
              <Icon src={deleteRed} onClick={()=>removeItem(index, file.name)} />
            </Box>
          </Flex>
          <Flex>
            <CardText mt='-22px'>{file.name}</CardText>
          </Flex>	
        </Wrapper>
      ))}
    </Wrapper>
  )

}

export default FileUploader;