import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import getOptions from '../../utils/options';
import HomeAPI from '../../services/home.api';
import { Box } from 'rebass';
import toast from '../../utils/toast';
import tracker from '../../utils/tracker'

const FileInput = styled.input.attrs({ type: 'file', multiple: true })`
	display: none;
`

const Label = styled.label`
  cursor: pointer;
  ${props => props.type === 'form_response' && css`width: 100%`}
`

const Button = styled(Box)`
	border: ${({ theme: { buttons } }) => '1px solid ' + buttons["default-outline"].borderColor};
	color: ${({ theme: { buttons, colors }, disabled }) => disabled ? colors.white : buttons["default-outline"].color};
	padding:  ${props => props.padding ? props.padding : ' 10px 16px '} ;
	background: ${props => props.background ? props.theme.colors[props.background] : props.theme.colors.white};
	border-radius: ${props => props.br ? props.br : '.2em'};
	text-align: center;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  background: ${props => props.disabled ? props.theme.colors.bluerGrey : props.theme.colors[props.background]};
`



const Uploader = ({ isButton = false, eventName, isAssigned = true, customerToken, type, updateFiles, onSuccess, onFailure,
  documenttype, documentSubType, filesExist, selectedFiles, isOnlySelectFile, updateFileInProgress, form, supported_files, throwError, 
  maxFileSize, isPermissionDenied, isPermissionGranted, requestNativePermissions, permissionsState, ...props }) => {
  supported_files = supported_files && supported_files?.length > 0 ? supported_files : ["image/*", ".doc", ".docx", 'application/pdf']

  const isHandleFileSelectClicked = useRef(false);
  const fileUploadRef = useRef();
  
  //isHigherVersion is the function which compares the current version with "23.8.3";
  const isHigherVersion = () => {
    const currentVersion = window.localStorage.getItem('APP-VERSION')?.split(".")?.map(Number) ?? [0, 0, 0];
    const baseVersion = [23, 8, 3]; //23.8.3 is the version when we added new code that is why it is like [23, 8, 3]
    if (currentVersion[0] > baseVersion[0]) return true;
    if (currentVersion[0] === baseVersion[0] && currentVersion[1] > baseVersion[1]) return true;
    if (currentVersion[0] === baseVersion[0] && currentVersion[1] === baseVersion[1] && currentVersion[2] > baseVersion[2]) return true;
    return false;
  }
  const handleFileSelect = (e, selectedFiles) => {
    const files = filesExist ? selectedFiles : (e.target.files || e.dataTransfer.files)
    const fileSizes = Array.from(files).map(file => file.size)
    if(maxFileSize && fileSizes.filter(size => parseInt(size) > parseInt(maxFileSize.split(" ")) * 1000000).length > 0) {
      let error = `File limit of ${maxFileSize} exceeded.`
      throwError(error)
      return;
    }

    const invalidFilesCount = Array.from(files).filter((file) => {
      const fileType =
        supported_files.includes("image/*") && file.type.startsWith("image/")
          ? "image/*"
          : file.type;
      return supported_files.indexOf(fileType) === -1;
    }).length;

    if (invalidFilesCount > 0) {
      toast.error("Uploading files in this format is not feasible.");
      return;
    }

    const fileList = Object.keys(files).map(file => files[file]);
    if(!filesExist){
      updateFiles(customerToken, fileList)
    }
    window.Promise.all(Array.from(files).map((file, i) => {
      var data = new FormData();
      data.append('file', file);
      if(type == 'verifiable_documents'){
        data.append('type', documenttype)
        data.append('sub_type', documentSubType)
      }else{
        data.append('assigned', isAssigned);

      }
      const config = {
        onUploadProgress: myUploadProgress(i)
      }
      return uploader(data, config);
    })).then((response) => {
      files.length > 0 && toast.success(`File has been uploaded successfully.`);
      onSuccess(response.map(res => res.data), customerToken)
      let ele = fileUploadRef.current;
      if(ele){
        ele.value = ''
      }
    }).catch((error) => {
      if(error?.response?.status === 422) {
        toast.error(`File cannot be uploaded.`)
      } else {
        toast.error(`Cannot upload the file now. please try again later.`)
      }
      onFailure(error)
    })
  }

  const uploader = (data, config) => {
    let options = getOptions(customerToken)
    if (filesExist) {
      updateFileInProgress()
    }
    return HomeAPI.fileUploadToServer({ data: data, ...options, ...config }, type)
  }

  const myUploadProgress = (fileIndex) => (progress) => {
    let percentage = Math.floor((progress.loaded * 100) / progress.total)
    let element = document.getElementById('fileProgress' + customerToken + fileIndex)
    let elements2 = document.querySelectorAll('#fileprogressPercentage' + customerToken + fileIndex)
    let splitPercent = percentage / 4
    let splitValues = [splitPercent, 2 * splitPercent, 3 * splitPercent, 4 * splitPercent]
    splitValues.map((value) => {
      if (element) {
        element.value = value
        element.style.background = value < 100 ? `linear-gradient(90deg, #e5eeff ${value}%, #FFF .5%)` : 'white'
      }
      if (form && elements2) {
        let text = value < 100 ? 'Uploading... ' : 'Upload files'
        let textContent = value < 100 ? text + `(${value}%)` : text
        elements2.forEach((a) => a.textContent = textContent)
      } else if (elements2) {
        let text = value < 100 ? 'Uploading ' : 'Uploaded '
        let textContent = text + value + '%'
        elements2.forEach((a) => a.textContent = textContent)
      }
    })
  }

  const selectFile = (e) => {
    tracker.track(eventName, { screen: 'eventName' })
    const files = e.target.files || e.dataTransfer.files;
    const fileTypes = Array.from(files).map(file => file.type)
    if (fileTypes.filter(type => supported_files.indexOf(type) === -1).length > 0) {
      toast.error(`Cannot upload file types other than pdf, png, jpg and jpeg .`)
      return;
    }
    const fileList = Object.keys(files).map(file => files[file]);
    updateFiles(customerToken, fileList)
  }

  //Remove this function when you are sure that every user is using updated app
  const prevVersionHandleFileSelectClick = () => {
    let fileElement = fileUploadRef.current;
    if (isPermissionGranted('storage')) fileElement.click() 
    else if (isPermissionDenied('storage')) toast.error('Please allow storage permission to upload the document')
    else requestNativePermissions(['storage'])
  }

  const handleFileSelectClick = () => {
    let fileElement = fileUploadRef.current;
    if (window.isEkincareApp()) {
      isHandleFileSelectClicked.current = true;
      if (!isHigherVersion()) {
        prevVersionHandleFileSelectClick();
        return;
      }
      if (isPermissionGranted('storage') && isPermissionGranted('camera')) fileElement.click();
      else if ((permissionsState.storage === 'prompt' || isPermissionDenied('storage')) && isPermissionGranted('camera')) requestNativePermissions(["storage"]);
      else if ((permissionsState.camera === 'prompt' || isPermissionDenied('camera')) && isPermissionGranted('storage')) requestNativePermissions(["camera"]);
      else requestNativePermissions(['storage', 'camera'])
    } else fileElement.click()
  }

  const prevUseEffectCondition = () => {
    if (isHandleFileSelectClicked.current) {
      if (permissionsState.storage !== 'prompt') handleFileSelectClick();
      isHandleFileSelectClicked.current = false;
    } 
  }

  useEffect(() => {
    if (!isHigherVersion()) prevUseEffectCondition();
    else {
      if (isPermissionGranted('storage') && isPermissionGranted('camera') && isHandleFileSelectClicked.current) {
        fileUploadRef.current.click();
        isHandleFileSelectClicked.current = false;
      }
    }
  }, [permissionsState]);

  return (
    <>
      <Label id='fileUpload' type={type} onClick={handleFileSelectClick}>
        {!filesExist ?
          <>
            {!isButton && props.children}
            {isButton && <Button background={props.background} disabled={props.disabled} bold size={props.btnSize} type={props.btnType} br={props.br}>{props.children}</Button>}
          </>
          :
          <>
            {isButton && <Button padding={props?.padding} disabled={props?.disabled} background={props.background} bold onClick={props?.disabled ? null : () => handleFileSelect(null, selectedFiles)} size={'mdL'} type={props.btnType} br={props.br}>{props.children}</Button>}
          </>
        }
      </Label>

      {
        !filesExist && <FileInput ref={fileUploadRef} onChange={isOnlySelectFile ? selectFile : (e) => handleFileSelect(e, true, selectedFiles)} accept={supported_files?.join(", ")} />
      }
    </>  
  )
}

Uploader.defaultProps = {
  filesExist: false,
  isOnlySelectFile: false
}



export default Uploader;