import React from 'react'
import { Flex } from 'rebass'
import { LottieContainer } from '../../atoms'
import { Text } from '../../atoms/typography'
import styled, { css } from 'styled-components'
import SpinnerBlueLoader from '../../../assets/animations/spinner-blue-loader.json'
import themes from '../../../themes'

export const OverlayLoader = styled(Flex)`
top: 0;
left: 0;
right: 0;
bottom: 0;
width: 100%;
z-index: 50;
${({ m }) => m && css`margin: ${m}`};
height: ${props => props.height || '100%'};
position: ${props => props.position || 'fixed'};
opacity: ${props => props.backgroundOpacity || '0.90'};
background-color: ${props => props.backgroundColor || themes.colors.backgroundColor};
`
const ReviewDetailPageLoader = ({ SpinnerRequired = true, textFontWeight = 'bold', textFontSize='md', text, subText, textColor='lightBlue', backgroundOpacity, position, height, backgroundColor, m}) => {
  return (
    <OverlayLoader backgroundOpacity={backgroundOpacity} backgroundColor={backgroundColor} position={position} height={height} m={m}>
      <Flex width={'100%'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
        {SpinnerRequired && <LottieContainer animationData={SpinnerBlueLoader} width={'100px'} height={'100px'}/>}
        <Flex flexDirection={['column', 'row']} alignItems={'center'}>
          {text && <Text color={textColor} fontSize={textFontSize} lineHeight='22px' bold={textFontWeight === 'bold'} bolder={textFontWeight === 'bolder'}>{text}&nbsp;</Text>}
          {subText && <Text color={'lightBlue'} fontSize={'md'} lineHeight='22px' bold>{subText}</Text>}
        </Flex>
      </Flex>
    </OverlayLoader>
  )
}

export default ReviewDetailPageLoader;