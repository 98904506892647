import React, { useState } from 'react';
import FreshChat from './react-freshchat';
import { Button, Card, Icon, ToggleVisibility } from '../components/atoms';
import { instantChat } from '../assets/icons';
import HelpFooter from '../components/molecules/HelpFooter';
import IconWithText from '../components/molecules/IconWithText';
import { Flex } from 'rebass';
import { Text } from '../components/atoms/typography';
import styled from 'styled-components';
import ListsWithHeader from '../components/organisms/lists/WithHeader';
import ReviewDetailPageLoader from '../components/organisms/loaders/ReviewDetailPageLoader';
import { useUserDetails } from './customHooks';

const TabContainer = styled(Flex)`
  cursor: pointer;
  padding: 2px 12px;
  &:hover {
    background-color: ${props => props.theme.colors.bodyHoverColor};
    border-radius: 5px;
  }
`

const NewFreshChat = ({ type, helpCardtext, helpCardBackgroundImage, helpCardMins, helpCardFooterProps, onClickHandler = () => {}, postOpenCallback = () => {} }) => {
  const [showChat, setShowChat] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user: { first_name, last_name, email, alternative_mobile_number, customer_id, sponsor} } = useUserDetails();
  const supportText = loading ? 'Connecting...' : 'Chat with us';
  
  const postOpenHandler = () => {
    setLoading(false);
    postOpenCallback();
  }

  const openSupportChat = () => {
    let nativeEventData = JSON.stringify({ action: 'open-support', payload: {} })
    window.postAndroidMessage(nativeEventData)
    window.postIOSMessage(nativeEventData)
  }

  const waitForFcWidget = () => new Promise(resolve => {
    const checkInterval = setInterval(() => {
      if (window.fcWidget) {
        clearInterval(checkInterval);
        resolve();
      }
    }, 1);
  });

  const desktopOnClickHandler = async () => {
    if (window.fcWidget?.isOpen()) return;
    if (!window.fcWidget?.isInitialized()) setLoading(true);
    setShowChat(true);
    onClickHandler();
    try {
      await waitForFcWidget();
      window.fcWidget.on('widget:opened', postOpenHandler);
      window.fcWidget.open();
    } catch (error) {
      console.error('Failed to load fcWidget.', error);
      setLoading(false);
    }
  };

  const handleChatButtonClick = () => window.isEkincareApp() ? openSupportChat() : desktopOnClickHandler()
  
  const onFreshChatInit = widget => {
    widget.user.setProperties({
      first_name, last_name, email, alternative_mobile_number, customer_id, sponsor
    });
  };

  const HeaderChatMenu = () => (
    <TabContainer mr={5} cursor={'pointer'} onClick={handleChatButtonClick}>
      <ToggleVisibility visibleOn={{ desktop: true, tablet: false, mobile: false }} as={Flex} flexDirection={'column'}>
        <IconWithText alignSelf={'center'} mr={3} size='lg' flexDirection='row' src={instantChat}><Text color='extraInformation' lineHeight='0px' fontSize='md' bolder>{supportText}</Text></IconWithText>
        <Text color='subtitle' fontSize={'xs'} bold>{'Mon - Sat | 8:00 AM - 6:00 PM'}</Text>
      </ToggleVisibility>
      <ToggleVisibility visibleOn={{ desktop: false, tablet: true, mobile: false }} as={Flex} flexDirection={'column'}>
        <Icon mr={3} size='xlmd' flexDirection='row' src={instantChat}/>
      </ToggleVisibility>
    </TabContainer>
  )
  
  const renderChatButton = () => {
    const cellOptions = [{ type: 'action', header: 'Help and Support', subHeader: 'Mon - Sat | 8 AM - 6 PM', icon: { src: instantChat, required: true, size: 'xxml' }, onClick: handleChatButtonClick }]
    switch (type) {
    case 'button':
      return (
        <Button onClick={handleChatButtonClick} letterSpacing='0px' borderRadius='5px' variant='primary' fontSize='md' width='170px' height='44px' bold>
          {supportText}
        </Button>
      );
    case 'header':
      return (
        <HeaderChatMenu/>
      );
    case 'footer_card':
      return (
        <HelpFooter helpCardClickHandler={handleChatButtonClick} text={helpCardtext} mins={helpCardMins} footerProps={helpCardFooterProps} backgroundImage={helpCardBackgroundImage} loading={loading} />
      );
    case 'mobile_profile_section':
      return (
        <Card borderRequired={false} mt={-4}>
          {loading ? 
            <ReviewDetailPageLoader SpinnerRequired={false} text={'Connecting...'} position={'relative'} backgroundOpacity={'1'} height={'82px'} textFontWeight='bolder' textFontSize='mdL' /> 
            :
            <ListsWithHeader listCellOptions={cellOptions}/>
          }
        </Card>        
      );
    default:
      return null;
    }
  };

  return (
    <>
      {renderChatButton()}
      {showChat && (
        <FreshChat
          token="01b94c77-80cf-4ed8-bc54-90dc7422837a"
          config={{ headerProperty: { direction: 'rtl', hideChatButton: true } }}
          onInit={onFreshChatInit}
        />
      )}
    </>
  );
};

export default NewFreshChat;