import React from 'react';
import NavGroup from '../atoms/NavGroup';

const TopMenu = ({children, onMouseLeave}) => {
  return(
    <NavGroup onMouseLeave={onMouseLeave}>
      {children}
    </NavGroup>
  )
}

export default TopMenu