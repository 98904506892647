import React from 'react';
import { Image, CardTitle, CardText, Button, Icon, ToggleVisibility, ShowMore } from '../atoms';
import styled from 'styled-components';
import { Flex, Box } from 'rebass';
import { backWhite } from '../../assets/icons'
import { backBlack } from '../../assets/icons'
import { withRouter } from 'react-router-dom';

const Container = styled.div`
	background-color: ${props => props.backgroundColor};
`

const ImageTag = styled(Image)`
	
`

const SubContent = styled.div`
	padding: 40px;
`

const ExtendedButton = styled(Button)`
	color: ${props => props.color};
	background-color: ${props => props.theme.colors.white};
	&:hover {
		color: ${props => props.color};
		background-color: ${props => props.theme.colors.white};
	}
	border: none;
	min-height: 40px;
`

const ExtendedFlex = styled(Flex)`
	height: 100vh;
	position: relative;
`

const ExtendedBox = styled(Box)`
	height: 100%;
`

const Head = styled.div`
	height: 15%;
	position: relative;
	z-index: 1;
`

const Body = styled.div`
	height: 45%;
	text-align: center;
	position: relative;
	z-index: 0;
`

const Footer = styled.div`
	height: 40%;
	position: relative;
	flex-direction: column;
    justify-content: flex-end;
    display: flex;
`

const ImageWithContent = ({ onShowMore, showMoreBgColor, btnTextColor, textColor, title, description, backgroundColor, src, btn={}, history: { goBack } }) => {

  let btnColor = btnTextColor || backgroundColor
  let backIcon = textColor === 'white' ? backWhite : backBlack

  return(
    <Container backgroundColor={backgroundColor} >
      <ToggleVisibility visibleOn={{mobile: false}} >
        <Flex justifyContent='space-between' width='100%' flexWrap='wrap'>
          <Box width={['0%', '50%', '60%']}>
            <SubContent>
              <Icon src={backIcon} size='lg' onClick={goBack} />
              <CardTitle color={textColor} mt='30px' fontSize='lg'>{title}</CardTitle>
              <CardText color={textColor} fontSize='md' mb='40px'>{description}</CardText>
              <ExtendedButton color={btnColor} minWidth='150px' size='md' type='default-outline' onClick={btn.actionToPerform} >{btn.text}</ExtendedButton>
            </SubContent>	
          </Box>	
          <Box width={['0%', '50%', '40%']}>
            <ImageTag src={src} />
          </Box>
        </Flex>		
      </ToggleVisibility>
      <ToggleVisibility visibleOn={{desktop: false, tablet: false}} >
        <ExtendedFlex justifyContent='space-between' width='100%' flexWrap='wrap'>
          <ExtendedBox width='100%' px='20px' pt='20px'>
            <Head>
              <Icon src={backIcon} size='lg' onClick={goBack} />
              <CardTitle color={textColor} mt='10px' fontSize='lg'>{title}</CardTitle>	
            </Head>
            <Body>
              <ImageTag src={src} height='100%' width='100%' />
            </Body>	
            <Footer>
              <CardText color={textColor} fontSize='md' mt='20px' mb='20px'>{description}</CardText>	
              <ExtendedButton block color={btnColor} minWidth='150px' mb='20px' size='md' type='default-outline' onClick={btn.actionToPerform} >{btn.text}</ExtendedButton>
              <ShowMore backgroundColor={showMoreBgColor} textColor={textColor} onShowMore={onShowMore} />
            </Footer>
          </ExtendedBox>
        </ExtendedFlex>
      </ToggleVisibility>	
    </Container>
  )
}

ImageWithContent.defaultProps = {
  textColor: 'white'
}

export default withRouter(ImageWithContent);