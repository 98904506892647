import React, { Suspense } from 'react';
import styled from 'styled-components';
import { Card, CardTitle, CardText, Button, Image, ToggleVisibility } from '../../atoms';
import {Flex, Box} from 'rebass';
import BackIcon from '../../../assets/icons/back.svg';
import { media } from '../../../utils/mediaBreakpoints';
import CustomDatePicker from '../../atoms/CustomDatePicker';
import { balloonDob } from '../../../assets/images';

const Wrapper = styled.div`
	text-align: ${props => props.textAlign};
	position: relative;
	min-height: 500px;
	${media.mobile`
		min-height: 100%;
	`}
`

const ErrorMsg = styled.p`
	color: ${props => props.theme.colors.danger};
	margin-top: 0px;
`
const Footer = styled.div`
	position: absolute;
	bottom: 0px;
	width: 100%;
`

const DateOfBirth = ({relation, dob, updateDob, goBack, goToNext, discardAddMember}) => {
	
  const isDisabled = () => {
    if (dob) {
      let dateFields = dob.split("-").map((item) => parseInt(item))
      if (!dateFields[0] || !dateFields[1] || !dateFields[2]){ return true }
      let newDate = new Date(dateFields[0], dateFields[1], dateFields[2])
      let isLeapYear = new Date(newDate.getFullYear(), 1, 29).getDate() === 29
      if (newDate.getDate() > 31) {return true}
      if (dateFields[1] > 12) {return true}
      if (newDate.getFullYear() < 1900) {return true}
      if ([4,6,9,11].includes(dateFields[1]) && newDate.getDate() > 30) {return true}
      if (dateFields[1] === 2 && (!isLeapYear && newDate.getDate() > 28)) {return true}
      if (dateFields[1] === 2 && (isLeapYear && newDate.getDate() > 29)) {return true}
    }		
    else {
      return true
    }
    return false
  }

  const errorMsg = () => {
    if (dob) {
      let dateFields = dob.split("-").map((item) => parseInt(item))
      if (dateFields[0] && dateFields[1] && dateFields[2]){
        let newDate = new Date(dateFields[0], dateFields[1], dateFields[2])
        let isLeapYear = new Date(newDate.getFullYear(), 1, 29).getDate() === 29
        if (newDate.getDate() > 31) {return 'Enter valid date'}
        if (dateFields[1] > 12) {return 'Enter valid date'}
        if (newDate.getFullYear() < 1900) {return 'Enter valid date'}
        if ([4,6,9,11].includes(dateFields[1]) && newDate.getDate() > 30) {return 'Enter valid date'}
        if (dateFields[1] === 2 && (!isLeapYear && newDate.getDate() > 28)) {return 'Enter valid date'}
        if (dateFields[1] === 2 && (isLeapYear && newDate.getDate() > 29)) {return 'Enter valid date'}
      }
      else {
        return 'Enter valid date'
      }
    }	
  }


  return(
    <Card px='20px' py='20px' width='100%'>
      <Wrapper textAlign='start'>
        <Flex width='100%' flexWrap='wrap'>
          <Box width={['50%']}>
            <Image src={BackIcon} width='20px' onClick={goBack} />
          </Box>
          <Box width={['50%']}>
            <CardTitle alignSelf='right' onClick={discardAddMember}>X</CardTitle>
          </Box>
        </Flex>
        <ToggleVisibility visibleOn={{desktop : false, tablet : false}}>
          <Flex justifyContent='center'>
            <Image src={balloonDob} width='100%' height='200px' mb='66px' />
          </Flex>
        </ToggleVisibility>
        <CardTitle mb='3px' pb='3px' bold>What is your {relation === 'Other' ? 'family member' : (relation.toLowerCase()+"'s")} date of birth?</CardTitle>
        <CardText mb='15px' color={['#7C8CAB', 'inherit']} >Knowing the age will help us predict health risks and personalize the journey.</CardText>
        <Suspense fallback={'Loading'}>
          <CustomDatePicker showYearDropdown={true} textAlign='left' placeholder='Date of birth' showMonthDropdown={true} dob={true} selectedDob={dob} onChange={updateDob} />
        </Suspense>
        <ErrorMsg>{errorMsg()}</ErrorMsg>
        <Footer>
          {isDisabled() ?
            <Button block={true} size='lg' type='stateless' disabled={true}>Next</Button>
            :
            <Button block={true} size='lg' type='primary' data-event-name="afm_date_of_birth" onClick={goToNext}>Next</Button>
          }
        </Footer>
      </Wrapper>
    </Card>
  )	

}

export default DateOfBirth;