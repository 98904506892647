import React from 'react';
import styled from 'styled-components';
import { upBlack, upWhite } from '../../assets/icons';
import Icon from './Icon';

const HalfCircleUp = styled.button`
  background-color: ${props => props.backgroundColor || '#9e978e'};
  border: 1px solid ${props => props.backgroundColor || '#9e978e'};
  height: 50px;
  width: 100px;
  border-top-left-radius: 110px;
  border-top-right-radius: 110px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
`

const Label = styled.label`
  font-size: ${props => props.theme.fontSizes['xs']};
  color: ${props => props.theme.colors[props.color]};
`

const ExtendedIcon = styled(Icon)`
  margin-top: -5px;
`

const ShowMore = ({textColor, onShowMore, ...props}) => {
  let icon = textColor === 'white' ? upWhite : upBlack
  return(
    <HalfCircleUp backgroundColor={props.backgroundColor} onClick={onShowMore} >
      <ExtendedIcon src={icon} size='xxl' />
      <Label color={textColor}>Show More</Label>
    </HalfCircleUp>
  )
}

ShowMore.defaultProps = {
  textColor: 'white'
}

export default ShowMore