import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import './assets/styles/tailwind.css'
import App from './App';
import { BrowserRouter } from 'react-router-dom';
// import serviceWorkerConfig from './utils/serviceWorkerConfig';
import * as serviceWorker from './serviceWorker';
import * as Sentry from "@sentry/react";

function getEnvironment() {
  const constantStr = "dbxtbhegnbvf3";
  if(window.location.host === 'app.ekincare.com' || window.location.host === 'pwa.ekincare.com') {
    return 'production';
  } else if(window.location.host.includes(constantStr)) {
    return 'amplify';
  }
}

Sentry.init({
  environment: getEnvironment(),
  "dsn": "https://cd105051f0274f20a4632252e59020ba@o65160.ingest.sentry.io/1488547",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  ignoreErrors: [/Unexpected identifier 's'. Expected '\)' to end an argument list\./],
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  sampleRate: 1
});

const root = createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.register(serviceWorkerConfig);
serviceWorker.register({
  onSuccess: () => console.log('service worker is registered'),
  onUpdate: registration => {
    let registrationWaiting = registration.waiting;
    if(registrationWaiting){
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
      registrationWaiting.addEventListener('statechange', e => {
        if (e.target.state === 'activated') {
          window.location.reload();
        }
      });
    }
  }
    
});
