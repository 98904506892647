import React from "react";
import styled from "styled-components";
import { Flex } from 'rebass';
import { infoGreenTick } from "../../assets/icons";
import Icon from "./Icon";
import { Text } from '../../components/atoms/typography';

const FlexWithBorder = styled(Flex)`
    border-width: 1.5px;
    border: 1.5px solid #f5f7f9;
    border-right-style: none;
    border-left-style: none;
    margin-left: 0;
    margin-right: 0;
    border-radius: 0;
   
`
const OfferBanner = ({text}) => {
  return (
    <FlexWithBorder px={4} py={4} ml="0px" mx={3} my={3} mt={'24px'}>
      <Icon ml={5} src={infoGreenTick} size="lg" />
      <Text
        pl={3}
        fontSize="sm"
        letterSpacing="0"
        color="extraInformation"
        bold
        pt={3}
        ml={4}
      >
        {text}
      </Text>
    </FlexWithBorder>
  );
};

export default OfferBanner;