import React from 'react';
import styled from 'styled-components';
import { Text } from '../atoms/typography';
import { Flex } from 'rebass';

const ExtendedDiv = styled.div`
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
  background-color: #7c8cab;
`

const ListItemsWithShape = ({header, list, ...props}) => {
  const {fontSize, pt, pb, lineHeight, color, textPaddingTop, mb} = props
  return (
    <>
      <Text pb={pb} mb={mb} color='title' fontSize='mdL' letterSpacing='0' lineHeight='30px' bold>{header}</Text>
      {list.map((item, index) => {
        return (
          <Flex flexDirection='row' key={index}>
            <Flex pt={pt}>
              <ExtendedDiv />
            </Flex>
            <Text pl={'12px'} pt={textPaddingTop} color={color} fontSize={fontSize} letterSpacing='0' lineHeight={lineHeight}>{item}</Text>
          </Flex>
        )
      })}
    </>
  )
}

ListItemsWithShape.defaultProps = {
  fontSize: 'md',
  lineHeight:'24px',
  pt: '19px',
  pb: '4px',
  color: 'extraInformation',
  textPaddingTop: '12px',
  mb: 0
}

export default ListItemsWithShape;