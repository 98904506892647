import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Flex } from 'rebass';
import appDownload from '../../assets/images/app-download.svg'
import { Image, Button, Card } from '../atoms';
import styled from 'styled-components';
import { Text } from '../atoms/typography';
import { android, ios} from '../../assets/icons';
import { isiOS} from '../../utils/common';

const ExtendedImg = styled.img`
  min-width: 164px;
  min-height: 176px;
`

const ExtendedButton = styled(Button)`
  padding: 14px 61px;
`
const ExtendedCard = styled(Card)`
  position: fixed;
  width: 100%;
  top: 350px;
  min-height: 100%;
`

const RedirectToApp = () => {

  const androidStore = 'https://play.google.com/store/apps/details?id=com.ekincare'
  const iosStore = 'itms-apps://itunes.apple.com/app/ekincare/id973037249?mt=8';

  const storeInfo = {
    'android': {url: androidStore, icon: android},
    'ios': {url: iosStore, icon: ios}
  }

  const currentDevice = isiOS ? storeInfo['ios'] : storeInfo['android']
  let target_url = isiOS ? iosStore : androidStore ;
  let redirect_url = isiOS ? 'com.ekincaredevelopment://' : "myapp://com.ekincare.SplashScreenActivity"
  let just_redirect = false;
  
  const showMessage = function(message_id) {
    let redirect_container_element = document.getElementById("redirect_container_element");
    if (redirect_container_element !== null) {
      redirect_container_element.style.display = "none";
    }
  
    let message_element = document.getElementById("message_container");
    if (message_element !== null) {
      message_element.style.display = "table";
    }
  
    let message_options = document.getElementsByClassName("message-option");
    Array.apply(null, message_options).forEach(function(option) {
      option.style.display = (option.id === message_id ? "table-cell" : "none");
    });
  };
  
  const doRedirect = () => {
    showMessage("loading_native");
    let start_time = new Date().getTime();
    setTimeout(function() {
      let interval = ((new Date().getTime()) - start_time);
      let opened_app = (document.hidden === true || (interval > 2500));
      if (!opened_app) {
        window.location.href = target_url;
      } else {
        showMessage("loaded_native");
      }
    }, 2000);
    window.location.href = redirect_url;
  };
  
  const openNativeApp = () => {
    doRedirect();
  };
  
  if (just_redirect) {
    window.addEventListener("load", doRedirect);
  }

  useEffect(() => {
    if(window.location.host === 'app.ekincare.com' || window.location.host === 'pwa.ekincare.com'){
      ReactGA.initialize('UA-117483722-3')
      ReactGA.pageview(window.location.pathname + window.location.search)
    }
  },[])


  return (
    <>
      <Flex id="redirect_container_element" height='100%' justifyContent='center' flexDirection='column'>
        <Card pt='24px' mb={6} height='60%' alignItems='center' justifyContent='center' borderRequired={false} flexDirection='column'>
          <ExtendedImg src={appDownload} alt="appDownload" />
          <Text textAlign='center' mx='64px' pt={'6px'} bold pb={'14px'} fontSize='md' color='lightBlue' lineHeight='22px'>ekincare works better if you switch to our app</Text>
          <ExtendedButton onClick={() => openNativeApp()} size={'mdL'} fontSize='md' letterSpacing='0' type='primary'>{'Open in the ekincare app'}</ExtendedButton>
        </Card>
        <ExtendedCard flexGrow='1' height='40%' alignItems='center' borderRequired={false} backgroundColor={'#f5f7f9'} flexDirection='column'>
          <Text mt={6} mb={3} bold fontSize='mdL' lineHeight='22px' color='bannerBackground'>Don’t have the app?</Text>
          <Text color='lightBlue' bold mb={'20px'} fontSize='md'>Access health benefits on the go</Text>
          <Image src={currentDevice.icon} height={'fit-content'} pt={[4,0]} onClick={() => window.open(currentDevice.url, '_blank')} width='113px'/>
        </ExtendedCard>
      </Flex>
      <Flex id="message_container" className="start-hidden">
        <Text color='white' bold fontSize='md' id="loading_native" className="start-hidden message-option">Opening the app...</Text>
        <Text color='white' bold fontSize='md' id="loading_web" className="start-hidden message-option">Sending you to play store...</Text>
        <Flex id="loaded_native" bold className="start-hidden message-option"> <Text color='white' fontSize='md'>Opened in the ekincare app</Text>
        </Flex>
      </Flex>
    </>
  )
}

export default RedirectToApp;


