import React from 'react';
import styled from 'styled-components';
const LineWithWordAtCenter = styled.div`
    overflow: hidden;
    text-align: center;
    margin: ${props=>props.margin};
    color: ${props=>props.color};
    &:after,&:before {
      background-color: ${props=>props.bgColor};
      content: "";
      display: inline-block;
      height: 1px;
      position: relative;
      vertical-align: middle;
      width: 50%;
    }
    &:before {
      right: 0.5em;
      margin-left: -50%;
    }
    &:after {
      right: -0.5em;
      margin-right: -50%;
    }
`
const HorizontalLineWithTextAtCenter = (props)=>{
  return (
    <LineWithWordAtCenter color={props.color} bgColor={props.bgColor} margin={props.margin}>{props.text}</LineWithWordAtCenter>
  )
}
export default HorizontalLineWithTextAtCenter;