import React, { useState } from 'react'
import { Flex} from 'rebass';
import { Text } from './typography';
import styled from 'styled-components';
import themes from '../../themes';

const ToggleContainer = styled(Flex)`
  
  background-color: ${themes.colors.backgroundColor};
  cursor: pointer;
  user-select: none;
  border-radius: 6px;
  padding: 2px;
  height: 30px;
  position: absolute;
  justify-content: space-evenly;
`

const ToggleButton = ({selectFilter, month, activeTab}) =>{
  const [selectedTab, setSelectedTab] = useState(activeTab)
  const dialogButton = {
    fontSize: '12px',
    lineHeight: '12px',
    fontFamily: 'ProximaNova-Semibold',
    cursor: 'pointer',
    backgroundColor: 'white',
    color: `${themes.colors.extraInformation}`,
    padding: '8px 9px 6px 9px',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
    minWidth: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '62px',
    borderRadius: '6px',
    boxSizing: 'border-box',
    position: 'relative',
  }

  const disabled = {
    color: `${themes.colors.subtitle}`,
    alignItems: 'center',
    fontFamily: 'ProximaNova-Semibold',
    fontSize: '12px',
    left: '2px',
    marginLeft: selectedTab === 'All Time' ? '22px' : '11px',
    marginRight: selectedTab === 'All Time' ? '22px' : '13px',

  }
  const disabledWithDivider = {
    color: `${themes.colors.subtitle}`,
    alignItems: 'center',
    fontFamily: 'ProximaNova-Semibold',
    fontSize: '12px',
    left: '2px',
    borderLeft: `1px solid ${themes.colors.borderGrey}`,
    margin: '4px',
    padding: '0px 18px'

  }


  const toggleSelectedTab = (value) =>{
    setSelectedTab(value)
    selectFilter(value)
  }

  return (
    <ToggleContainer>
      <Text align='center' style={selectedTab === 'All Time' ? dialogButton: disabled} onClick={() => disabled ? toggleSelectedTab('All Time') : null}>All Time</Text>
      <Text align='center' style={selectedTab === 'Year' ? dialogButton : (selectedTab === 'Month' ? disabledWithDivider : disabled)} onClick={() => disabled ? toggleSelectedTab('Year') : null}>Year</Text>
      {month && <Text align='center' style={selectedTab === 'Month' ? dialogButton : (selectedTab === 'All Time' ? disabledWithDivider : disabled)} onClick={() => disabled ? toggleSelectedTab('Month') : null}>Month</Text>}      
    </ToggleContainer>
  )
}
export default ToggleButton