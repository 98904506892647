import React from 'react'
import styled , {css} from 'styled-components'
import { Flex, Box } from 'rebass'
import { Card, Icon } from '../atoms'
import { Text } from '../atoms/typography'
import { IconWithText } from '.'
import chatWithUs from '../../assets/icons/HealthCheckDrawer/chat-with-us.svg'
import ReviewDetailPageLoader from '../organisms/loaders/ReviewDetailPageLoader'

//the below style is specific to the ewap main screen
const ExtendedCard = styled(Card)`
  ${({backgroundImage}) => backgroundImage && css`background-image: url(${backgroundImage}); background-position: top -10px left -8px; background-size: 105% 95%;`};
  -webkit-tap-highlight-color: transparent;
`

const HelpFooter = ({ src=chatWithUs, mins='1 min', text='Chat with us', footerProps, backgroundImage, loading, helpCardClickHandler = () => {} }) => {
  const {fontSize, color} = footerProps || {}

  return (
    <ExtendedCard onClick={helpCardClickHandler} backgroundColor='borderGrey' borderRequired={false} px='16px' py='18px' cursor='pointer' backgroundImage={backgroundImage}
      boxShadow='0px 1px 8px rgba(0, 0, 0, 0.1)' borderRadius='10px'>
      {loading ? 
        <ReviewDetailPageLoader SpinnerRequired={false} text={'Connecting...'} position={'relative'} backgroundOpacity={'1'} backgroundColor='borderGrey' height={'82px'} textFontWeight='bolder' textFontSize='lg'/> 
        :
        <Flex flexDirection='column'>
          <Flex justifyContent='space-between'>
            <Box display='flex' flexDirection='column'>
              <Text fontSize='mdL' color='extraInformation' bold>{text}</Text>
              <Text fontSize='sm' color='subtitle' >{`typically replies in ${mins}`}</Text>
            </Box>
            <Box width={['30%', '30%', '30%']} justifyContent='center'>
              <Icon src={src} size='20px' height='40px'/>
            </Box>
          </Flex>
          {footerProps && 
            <IconWithText src={footerProps.src} flexDirection='row' mr='6px' marginBottom='-18px' 
              marginTop='16px' mx='-16px' px='16px' pt='10px' pb='8px' bgColor='mediumDarkGrey' borderRadius='0 0 10px 10px'>
              <Text color={color} fontSize={fontSize} bold>{footerProps.text}</Text>
            </IconWithText>}
        </Flex>}
    </ExtendedCard>
  )
}

export default HelpFooter;
