import styled, { css } from 'styled-components';
import { media } from '../../utils/mediaBreakpoints';

const NavGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${props => props.p || '0px 40px'};
  ${({ml}) => ml && css` margin-left: ${ml}`};
  ${({height}) => height && css` height: ${height}`};
  ${({width}) => width && css` width: ${width}`};
  ${media.tablet`
    padding: ${props => props.p || '0px 20px'};
  `};
`
export default NavGroup;