import React from 'react'
import { Flex } from 'rebass'
import { Text } from './typography'
import styled from 'styled-components'

const ETAWrapper = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: ${p => p.theme.colors.lighterBlueGrey};
`
const ETA = ({eta}) => {

  return(
    <ETAWrapper borderRadius='6px' mb='24px' >
      <Text color='extraInformation' lineHeight='19px'>Estimated delivery</Text>
      <Text color='extraInformation' bold>{eta}</Text>
    </ETAWrapper>
  )
}
export default ETA;