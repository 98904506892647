
import { POPULAR_FUNCTION_TESTS, POPULAR_LAB_TESTS, POPULAR_PACKAGES, PREVIOUS_PACKAGES } from '../../constants/LabTests.constant';
import {ADD_TO_CART, ITEMS_ADD_REMOVE, CLEAN_UP_CART} from '../../constants/pharmacy.constants'
import HomeAPI from '../home.api';


const LabtestReducer = (state, action) => {
  const { type, ...data } = action;
  switch(type) {
  case PREVIOUS_PACKAGES: {
    return {...state,previouslyBookedPackages:data.payload.previously_booked_packages}
  }
  case POPULAR_FUNCTION_TESTS: {
    return {...state,popularFunctionTests:data.payload.popular_function_tests}

  }
  case POPULAR_LAB_TESTS: {
    return {...state,popularLabTests:data.payload.popular_lab_tests}
  }
  case POPULAR_PACKAGES :{
    return {...state,popularPackages:data.payload.popular_packages}
  }
  case ADD_TO_CART: {
    if(data.reOrder){
      return {...state, cart: [...state.cart, {...data.cart}], lookForItemsAdded: !state.lookForItemsAdded}
    }

    if ( !data.globalCartUpdate ) {
      HomeAPI.updatePharmacyCart({ data : {product: {...data.cart, quantity: data?.cart.min_limit_per_order || 1}, action_type: 'ITEM_ADD' }}, () => {}, () => {})
    }
    return {...state, cart: [...state.cart, {...data.cart, quantity: data?.cart.min_limit_per_order || 1}], lookForItemsAdded: !state.lookForItemsAdded, skipPrescription: false}
  }
  case ITEMS_ADD_REMOVE: {
    let newState = data.cart || state.cart
    let product = state.cart.findIndex((item) => item.id === data.productId)

    if(product != -1){
      newState[product].quantity = data.itemsAdded
    }
    if(data.itemsAdded == 0){
      let newCart = newState.filter((product) => product.id != data.productId)
      return {...state, lookForItemsAdded: !state.lookForItemsAdded, cart: newCart, prescriptionsReviewData: {} }
    }
    return {...state, lookForItemsAdded: !state.lookForItemsAdded, cart: newState}
  }
  
  case CLEAN_UP_CART: {
    return {...state, prescriptionsReviewData: {}, cart: [], cartSummary: {}, selectedPrescriptions:  [], pastPrescriptions: [] }
  }

 
  default: {
    return state
  }
  }
}

export default LabtestReducer