import React from 'react'
import { Card, Icon, ToggleVisibility } from '../../atoms';
import { Box, Flex } from 'rebass';
import ekincarePoint from '../../../assets/images/benefits/ekincare-point.svg';
import Text from '../../atoms/typography/Text';
import notificationNew from '../../../assets/icons/notificationNew.svg';
import notificationAlertNew from '../../../assets/icons/notificationAlertNew.svg';
import { Link, useHistory } from 'react-router-dom';
import { MOBILE_ACCOUNT, NOTIFICATIONS, REWARDS } from '../../../routes/private';
import useFetchNotifications from '../../../utils/customHooks/useFetchNotifications';
import useIsMobileView from '../../../utils/customHooks/useIsMobileView';
import useUserDetails from '../../../utils/customHooks/useUserDetails';
import styled from 'styled-components';
import { formatNumberWithCommas } from '../../../utils/common';
import CircleWithDynamicText from '../../molecules/CircleWithDynamicText';
import tracker from '../../../utils/tracker';
import { ekincareLogo } from '../../../utils/constants';

const AnimatedCard = styled(Card)`
  -webkit-tap-highlight-color: transparent;
  transition: transform 0.4s;
  &:hover {
    transform: scale(1.1);
  }
`;

const NameBanner = () => {
  const { push } = useHistory();
  const { rewards, services, partner_logo, company_logo, user } = useUserDetails();
  const logo_src = partner_logo || company_logo || ekincareLogo;
  const fullName = user?.name || user?.first_name;
  const { checkUnreadNotifications } = useFetchNotifications();
  const isMobileView = useIsMobileView();
  const notification_scr = checkUnreadNotifications() ? notificationAlertNew : notificationNew;
  const rewardText = rewards.rewardsPoints > 0 ? formatNumberWithCommas(rewards.rewardsPoints) : 'Earn H-Points';

  const getShortName = (name = '' ) => {
    const fullName = name;
    const [firstName, lastName] = fullName.split(' ');
    const shortName = (lastName ? `${firstName.charAt(0)}${lastName.charAt(0)}` : firstName.charAt(0)).toUpperCase();
    return shortName;
  }

  const notificationClickHandler = () => (
    push(NOTIFICATIONS),
    tracker.track('home_screen', {action: 'notification'})
  )

  const profileClickHandler = () => (
    push(MOBILE_ACCOUNT),
    tracker.track('home_screen', {action: 'user_profile'})
  )

  return (
    <Flex justifyContent={'space-between'} alignItems={'center'}>
      <ToggleVisibility as={Flex} width={'107px'} visibleOn={{mobile: true, desktop: false, tablet: false}}>
        {partner_logo !== '' && <Icon height='auto' width='auto' maxHeight={'45px'} maxWidth={'107px'} src={logo_src}/>}
      </ToggleVisibility>
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
        {services?.enable_rewards !== 0 && 
          <AnimatedCard as={Link} to={REWARDS} onClick={()=>tracker.track('home_screen', {action: 'rewards'})} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} borderRadius={'10px'} borderColor={'borderGrey'} cursor='pointer' mr={['8px', '18px']} py={'6px'} px={'10px'}>
            <Icon size={'lg'} pb={1} mr={3} src={ekincarePoint}/>
            <Text color='extraInformation' fontSize='sm' lineHeight='12px' bolder>{rewardText}</Text>
          </AnimatedCard>
        }
        <Icon mr={!isMobileView && '18px'} onClick={notificationClickHandler} pointer size={'lg'} src={notification_scr}/>
      </Box>
      <ToggleVisibility visibleOn={{mobile: false, desktop: true, tablet: true}}>
        <CircleWithDynamicText text={getShortName(fullName)} width={'38'} height={'38'} fontSize='16px' color='#324467' circleWidth='19'
          backgroundColor='#c4f1d8' onClick={profileClickHandler} fontFamily={"ProximaNova-Semibold"} circleHeight='19' circleRadius='19'
        />
      </ToggleVisibility>
    </Flex>
  )
}
export default NameBanner;