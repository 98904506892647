import styled from 'styled-components';
import { Box } from 'rebass';

const ButtonText = styled(Box).attrs(() => ({as: 'span'}))`
	margin-bottom: 0.35em;
	color: ${props => props.theme.body};
  font-size: 0.875rem;
  font-family: ProximaNova-Semibold, sans-serif;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  margin: 0;
  display: block;
`

export default ButtonText