import React, { useState } from 'react';
import styled from 'styled-components';
import { Card, CardTitle, CardText, Input, Button, Dropdown, Image, ToggleVisibility } from '../../atoms';
import { media } from '../../../utils/mediaBreakpoints';
import { Flex, Box} from 'rebass';
import BackIcon from '../../../assets/icons/back.svg';
import Text from '../../atoms/typography/Text';

const Wrapper = styled.div`
	height: auto;
	text-align: ${props => props.textAlign};
	position: relative;
	min-height: 500px;
	${media.mobile`
		min-height: 100%;
	`}
`

const Footer = styled.div`
	position: absolute;
	bottom: 0px;
	width: 100%;
`

const alphaRegex = /^[A-Za-z ]+$/;

const Name = ({relation, name, img, updateName, gender, goBack, goToNext, updateGender, discardAddMember}) => {
	
  const [open, updateOpen] = useState(false)

  const isDisabled = () => {
    let disabled = false
    if (!name || name.trim() === "" || !gender){
      disabled = true
    }

    if(!disabled && !alphaRegex.test(name)){
      disabled = true;
    }

    return disabled
  }

  const updateMemberGender = (item) =>{
    updateGender(item)
    updateOpen(false)
  }

  const Gender = () => {
    const areParents = ["Father", "Father in law", "Mother", "Mother in law"].includes(relation);
    const areMaleParents = ["Father", "Father in law"].includes(relation);
    const GENDER_OPTIONS = areParents ? (areMaleParents ? ["Male", "Non-binary"] : ["Female", "Non-binary"]) : ['Male', 'Female', 'Non-binary']

    return <Dropdown 
      listOpen={open} selectedItem={gender} onChange={updateMemberGender} 
      title={gender ? gender : 'Gender'}
      list={GENDER_OPTIONS} 
      toggleList={()=>updateOpen(!open)} 
    />
  }

  const getTitle = () => {
    if (relation === 'Other') return "family member's";
    if (relation === 'Siblings') return "sibling's";
    if (relation === 'Children') return "child's";
    return `${relation.toLowerCase()}'s`;
  }

  return(
    <Card px='20px' py='20px' width='100%'>
      <Wrapper textAlign='start'>
        <Flex width='100%' flexWrap='wrap'>
          <Box width={['50%']}>
            <Image src={BackIcon} width='20px' onClick={goBack} />
          </Box>
          <Box width={['50%']}>
            <CardTitle alignSelf='right' onClick={discardAddMember}>X</CardTitle>
          </Box>
        </Flex>
        <ToggleVisibility visibleOn={{desktop : false, tablet : false}}>
          <Flex justifyContent='center'>
            <Image src={img} width='200px' height='200px' mb='66px' />
          </Flex>
        </ToggleVisibility>
        <CardTitle mb='3px' pb='3px' bold color='#324467'>What is your {getTitle()} name?</CardTitle>
        <CardText mb='0px' pb='0px' color={['#7C8CAB', 'inherit']} >Enter your full name, first name followed by last name</CardText>
        <CardText mb='15px' color={['#7C8CAB', 'inherit']}>For example: Anu Gupta</CardText>
        <Input width='100%' placeholder='Enter name' name='firstName' data-testid="name-input" value={name} onChange={updateName} />
        { name && !alphaRegex.test(name) && <Text color='red' mt={'6px'} fontSize='sm'>First name and Last name should only contain alphabets</Text> }
        <Gender />
        <Footer>
          {isDisabled() ?
            <Button block={true} size='lg' type='stateless' disabled={true}>Next</Button>
            :
            <Button block={true} size='lg' type='primary'data-event-name='afm_enter_name' data-event-data={JSON.stringify({'name':name})} onClick={goToNext}>Next</Button>
          }
        </Footer>
      </Wrapper>
    </Card>
  )	

}

export default Name;