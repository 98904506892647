import styled from 'styled-components';
import { Box } from 'rebass';

const MenuText = styled(Box).attrs(() => ({as: 'span'}))`
	color: ${props => props.theme.darkerGrey};
	font-family: ProximaNova-Semibold;
	letter-spacing: .03em;
	font-size: 0.813em;
	font-size: ${({ theme: { fontSizes }, fontSize }) => fontSizes[fontSize] || '0.875rem'};

`

export default MenuText