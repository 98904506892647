import React from 'react';
import styled from 'styled-components';
import {Box} from 'rebass'
import CardTitle from '../typography/CardTitle';

const AlertWrapper = styled(Box)`
  background-color: ${props => ({theme: {alerts}}) => alerts[props.type]};
  display: ${props => props.display ? 'block' : 'none' };
  padding: 0.75em;
  margin: 0.5em 0;
  font-size: ${props => props.fontSize + 'px'};
`

const Alert = ({children, type, display, title, fontSize='14px'}) => {
  return (
    <AlertWrapper type={type} display={display} fontSize={fontSize} title={title} >
      <CardTitle>{title}</CardTitle>
      {children}
    </AlertWrapper>
  )
}
  
export default Alert;