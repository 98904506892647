import React, { Fragment } from 'react';
import { CardTitle, Icon, CardText } from '../atoms';
import { Flex, Box } from 'rebass';

const HeaderListItemsWithIcon = ({list=[], header, descriptionBold}) => {
  return(
    <Fragment>
      {header && <CardTitle fontSize='md' mt='10px' mb='0' pb='0'>{header}</CardTitle>}
      {list.map((item) => (
        <Flex width='100%' flexWrap='wrap' justifyContent='flex-start' mb='10px' key={item.id}>
          <Box width={['13%', '7%']}>
            <Icon src={item.icon} size={item.iconSize || 'lg'} />
          </Box>
          <Box width={['87%', '93%']}>
            {item.title && <CardTitle fontSize='sm' mb='0px' pb='0px'>{item.title}</CardTitle>}
            {item.description && descriptionBold && <CardTitle color='darkestGreyNew' fontSize='sm'>{item.description}</CardTitle>}
            {item.description && !descriptionBold && <CardText color='darkestGreyNew' fontSize='sm'>{item.description}</CardText>}
          </Box>
        </Flex>
      ))}
    </Fragment>	
  )
}

HeaderListItemsWithIcon.defaultProps = {
  descriptionBold: true
}

export default HeaderListItemsWithIcon;