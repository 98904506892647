import styled from "styled-components";
import { Box } from 'rebass';

const CaptionText = styled(Box).attrs(() => ({as: 'span'}))`
	margin-bottom: 0.35em;
	color: ${props => props.theme.grey};
  font-size: 0.75rem;
  font-family: ProximaNova, sans-serif;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  margin: 0;
  display: block;
`

export default CaptionText