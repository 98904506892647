import React from 'react';
import {Flex} from 'rebass';
import {Text} from '../../atoms/typography'
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { directionRight } from '../../../assets/icons';
import { Icon } from '../../atoms';
import tracker from '../../../utils/tracker'
import {ToolTip} from '../../atoms'
import { media } from '../../../utils/mediaBreakpoints';

const Container = styled(Link)`
    display: flex;
    background-color: ${props => props.theme.colors[props.backgroundColor] || 'white'};
    padding: ${({ padding }) => padding};
    cursor: pointer;
    text-decoration: none;
    :hover {
      background-color: ${props => props.theme.colors[props.bodyHoverColor] || props.theme.colors.bodyHoverColor};
    }
    -webkit-tap-highlight-color: ${({theme}) => theme.colors.bodyHoverColor};
    // border-top: ${props => typeof(props.border) === 'boolean' && props.border ? ({ theme: { colors }}) => '1px solid ' + colors.borderColor : props.border};
    border-bottom: ${props => typeof(props.border) === 'boolean' && props.border ? ({ theme: { colors }}) => '1px solid ' + colors.borderColor : props.border};
    width: 100%;
`

const IconContainer = styled.div`
    height: 100%;
    margin: auto 10px;
    padding-left: 5px;
    ${ media.mobile `
       margin-top: 6px;
       min-width : 32px;
       min-height : 32px;
       padding: 0px; 
    `}
`
const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-family: sans-serif;
    padding: 5px;
    margin: ${({margin}) => margin || '0 10px'};
`

const Header = styled(Text)`
    font-family: "ProximaNova-Semibold";
    color: ${props => props.theme.colors[props.color]};
    font-size: ${({ theme: { fontSizes }}) => fontSizes.md};
    word-break: break-all;
`

const SubHeader = styled(Text)`
    color: ${props => props.theme.colors[props.color]};
    font-size: ${({ theme: { fontSizes }}) => fontSizes.sm};
    margin: ${({subTextmargin}) => subTextmargin || '3px 0'};
`

const SubSubHeader = styled.div`
    font-size: ${({ theme: { fontSizes }}) => fontSizes.md};
    color: ${props => props.theme.colors[props.color]};
    margin: 3px 0;
    font-family: ${({ theme: { fonts }}) => fonts.semiBold};

`

const RouteLink = styled(Link)`
    text-decoration: none;
    color: ${({ theme: { colors }}) => colors.blue};
    font-size: 12px;
    background-color: ${({ theme: { colors }}) => colors.lighterBlue};
    border-radius: 10px;
    padding: 1px 10px;
    margin: 2px 0;
    width: max-content;
    &:hover {
      background-color: ${({ theme: { colors }}) => colors.blue};
      color: ${({ theme: { colors }}) => colors.lightestBlue};
    }
`
const DirectionContainer = styled.div`
    display: flex;
    cursor: pointer;
    opacity: 0.6;
    align-items: center;
    margin-left: auto;
    margin-right: 15px;
`

/**
 * Pass in the components through the arguments. if no arguments passed defaults are used.
 * with the link: if to and text are present renders default styles. if link is a object render the react component.
 * Can add a table-cell without an icon when required falg is false.
 */
const ListCell = ({
  history, header, subHeader, backgroundColor, bodyHoverColor, muted, packageSubType,
  subSubHeader, icon, link, direction, border, onClick, collectionType, toolTipMsg,
  className, padding, textMargin, subTextmargin, eventName, eventData, as, calledFromSponserPackages, subSubHeaderColor, Icons, packageId, onClickViewButton, openTypesofPackagesModal
}) => {
  const { size } = icon;
  const onLinkClick = (e, ...args) => {
    e.preventDefault()
    onClick && onClick(...args)
    if(link.external) {
      window.open(link.to, '_blank')
    } else if(link.to) {
      history.push(link.to)
    }
    eventData ? tracker.track(eventName, eventData) : tracker.track(eventName)
  }
  return (
    <Container as={as} border={border} onClick={onLinkClick} bodyHoverColor={bodyHoverColor} data-testid={header} backgroundColor={backgroundColor} className={className} padding={padding}>
      {(icon.required && !React.isValidElement(icon) || React.isValidElement(icon)) && <IconContainer>
        {React.isValidElement(icon) ? icon : icon.src ? <Icon src={icon.src} size={size || 'xxl'} mt={icon?.mt || '0px'}/> : null}
      </IconContainer>}
      <TextContainer margin={textMargin}>
        {header && <Header color={muted ? 'darkGreyNew' : 'extraInformation'}>{header}</Header>}
        <Flex>
          {subHeader && <SubHeader subTextmargin={subTextmargin} color={muted ? 'darkGreyNew' : 'lightGrey'}> {subHeader}</SubHeader>}
          {calledFromSponserPackages && packageSubType !== 'covid' && <Text fontSize='sm' bold color={muted ? 'darkGreyNew' : 'cornflowerBlue'} mt= '3px' ml='6px' onClick={()=> onClickViewButton(packageId)}>View</Text>}
        </Flex>
        {link && (React.isValidElement(link) ? link : (link.text && link.to) ? <RouteLink to={link.to}>{link.text}</RouteLink> : null)}
        {subSubHeader ? <SubSubHeader color={muted ? 'darkGreyNew' : subSubHeaderColor}>{subSubHeader}</SubSubHeader> : null}
        {calledFromSponserPackages && <Flex>
          {Icons.map((icon,idx)=>{
            return (
              <Flex mt='10px' mr='10px' key ={idx}>
                <Icon src={icon.src} />
                {collectionType !== icon.id && collectionType !==null ? <ToolTip fontSize='14px' fontFamily={'Semibold'} message={toolTipMsg} position='top-left' color='extraInformation' ><Text fontSize='sm' color={collectionType === icon.id || collectionType === null ? 'subtitle' : 'darkGreyNew'} bold ml='5px' onClick={()=>{openTypesofPackagesModal(true)}}>{icon.collectionType}</Text></ToolTip>:
                  <Text fontSize='xs' color={collectionType === icon.id || collectionType === null ? 'subtitle' : 'darkGreyNew'} bold ml='5px' onClick={()=>{openTypesofPackagesModal(true)}}>{icon.collectionType}</Text>}
              </Flex>
            )
          })}
        </Flex>}
      </TextContainer>
      {direction && 
        <DirectionContainer>
          {
            React.isValidElement(direction) ? direction :
              <Icon src={icon.secondary && icon.secondary.src || directionRight} size={icon.secondary && icon.secondary.size || 'xs'}/>
          }
        </DirectionContainer>
      }
    </Container>)
}

ListCell.defaultProps = {
  icon: {},
  link: {},
  border: false,
  direction: true,
  padding: '10px 0px',
  as: Link
}

export default withRouter(ListCell);