import styled from 'styled-components';
import { Box } from 'rebass';

const MenuItemText = styled(Box).attrs(() => ({as: 'a'}))`
	color: ${props => props.theme.darkestGrey};
  padding: 5px 35px 5px 15px;
  display: block;
  font-size: 13px;
  font-family: ${({ theme: { fonts }}) => fonts.normal};
`

export default MenuItemText