/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from 'react';
import { UserDetailsContext } from '../contexts';
import HomeAPI from '../../services/home.api';

const useUserDetails = (refresh=false) => {
  const { userDetails: { user, familyMembers, familyDoctor={}, services={}, rewards={}, stepathon={}, productType, other_members_info={}, valuePlusSubscriber, company_logo, partner_logo, checks }, updateUserDetails, updateDashboardData, } = useContext(UserDetailsContext)
  const [isLoading, updateIsLoading] = useState(refresh)
  const [isError, updateIsError] = useState(false)
  
  // TODO:: change api to provide name of present user or change API.
  useEffect(() => {
    if(isLoading) {
      fetchAndUpdateUserDetails()
    }
  }, [isLoading])

  const fetchAndUpdateUserDetails = async() => {
    try {
      const responses = await fetchData()
      const { family_doctor, value_plus_subscriber, reward_points, rewards_active, ...user } = responses[0].data.members.find((member) => member.is_logged_in === true) || {};
      const { members, services, company_logo, partner_logo, ...other_info } = responses[0].data;
      updateUserDetails({
        user,
        familyMembers: members,
        familyDoctor: family_doctor || {},
        services: services,
        other_members_info: other_info,
        rewards: { rewardsPoints: reward_points, rewardsActivated: rewards_active },
        valuePlusSubscriber: value_plus_subscriber,
        company_logo, partner_logo,
        checks: responses[1]?.data ?? {}
      })
    }catch(err) {
      updateIsError(true)
    }finally {
      updateIsLoading(false)
    }
  }

  const callFamilyMembersAPI = () => {
    HomeAPI.fetchFamilyMembers({}, (response) => {
      if(response.status === 200) {
        const { family_doctor, value_plus_subscriber, reward_points, rewards_active, ...user } = response.data.members.find((member) => member.is_logged_in === true) || {};
        const { members, services, company_logo, partner_logo, ...other_info } = response.data;
        updateUserDetails({
          user,
          familyMembers: members,
          familyDoctor: family_doctor || {},
          services: services,
          other_members_info: other_info,
          rewards: { rewardsPoints: reward_points, rewardsActivated: rewards_active },
          valuePlusSubscriber: value_plus_subscriber,
          company_logo, partner_logo,
          checks: checks
        })
        updateIsLoading(false)
      }
    }, () => {
      updateIsError(true)
      updateIsLoading(false)
    })
  }


  const fetchData = () => Promise.all([HomeAPI.fetchFamilyMembers({}), HomeAPI.fecthNewUserToPharmacy({})])

  const isAuthenticated = user && user.id
  return {
    isLoading, isError, user, familyDoctor, familyMembers, company_logo, partner_logo,
    isAuthenticated, updateUserDetails, services, updateDashboardData, other_members_info,
    requireDataUpdation: updateIsLoading, rewards, stepathon, productType, valuePlusSubscriber,
    checks, callFamilyMembersAPI
  };
}

export default useUserDetails;
