import styled from 'styled-components';

const ArrowUp = styled.span`
  width: 0; 
  height: 0; 
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid ${({theme}) => theme.colors.title};
  cursor: pointer;
  margin-top: 10px;
  margin-left: 10px;
`
export default ArrowUp;