import React from 'react';
import Modal from '../../molecules/Modal'
import AppDownloadTile from '../../molecules/AppDownloadTile'

const MobileOnlyFeatureModal = ({closeModal, closeOnClickOverlay = true}) => {
  return(
    <Modal size='xxs' closeModal={()=>closeModal()} closeOnClickOverlay={closeOnClickOverlay} type="modal-root">
      <AppDownloadTile closeModal={closeModal} />
    </Modal>
  )
}

export default MobileOnlyFeatureModal;