// Higher order component, that renders the navbar(in web) and bottom navbar in mobile
import React, { useState, useEffect, useReducer } from 'react';
import { Switch } from 'react-router-dom';
import { privateRoutes } from '../routes';
import { Main } from '../components/atoms';
import { BottomBar, Navbar, LayoutModalsConsumer } from '../components/organisms/layouts';
import { ModalContext, NavbarContext, GlobalContext, BookingHistoryContext } from '../utils/contexts';
import combineReducers from '../services/reducers/combineReducers'
import ekincareReducer from '../services/reducers/ekincareReducer'
import BookingHistoryReducer from '../services/reducers/BookingHistoryReducer'
import {DASHBOARD, BENEFITS, IMPROVE_PAGE, HEALTH_PAGE, PROFILE, HOME, CONSENT_ISOS, MOBILE_ACCOUNT } from '../routes/private';
import pharmacyReducer from '../services/reducers/pharmacyReducer'
import { pharmacyInitialState, healthChecksInitialState, selectedLabtestInitialState } from '../store'
import useUserDetails from '../utils/customHooks/useUserDetails';
import { isEmpty } from '../utils/common';
import DobAndGenderUpdate from '../components/organisms/dashboard/DobAndGenderUpdate';
import { targetName } from '../utils/constants';
import LabTestContext from '../utils/contexts/LabTestContext';
import LabtestReducer from '../services/reducers/LabtestReducer';


const Layout = ({ history }) => {
  const bookingHistoryIntialState = { currentTabIndex: 0 }
  const [bookingHistoryStore, bookingHistorydispatch] = useReducer(BookingHistoryReducer, bookingHistoryIntialState)
  const [labtestStore,labtestDispatch] = useReducer(LabtestReducer,selectedLabtestInitialState)
  const [data, updateModalContent] = useState({})
  const [navbarData, updateNavbarData] = useState({})
  const { user } = useUserDetails();
  const [profileUpdateModal, setProfileUpdateModal] = useState(false)
  const [mainState, mainReducer] = combineReducers({
    pharmacy: [pharmacyReducer, pharmacyInitialState],
    healthChecks: [ekincareReducer, healthChecksInitialState]
  })
  const [globalState, dispatch] = useReducer(mainReducer, mainState)

  const privacyNoticeAccepted = ((targetName === 'isos' && window.localStorage.getItem('privacyNoticeAgree') === 'true') || targetName !== 'isos')
  
  const updateContent = (newData) => {
    updateModalContent({...data, ...newData})
  }

  useEffect(() => {
    if (Object.keys(user)?.length === 0) return;
    if ((isEmpty(user.gender) || isEmpty(user.date_of_birth)) && privacyNoticeAccepted) setProfileUpdateModal(true)
  }, [user,privacyNoticeAccepted])

  const hasPrescriptionPending = window.localStorage.getItem('HAS_PRESCRIPTION_PENDING') === "TRUE";

  useEffect(() => {
    if (window.shouldDisableMoengage && typeof window.shouldDisableMoengage === 'function') {
      if (!window.shouldDisableMoengage()) {
        window.moeInit();
      }
    }
  }, []);
  

  useEffect(() => {
    if(!privacyNoticeAccepted && user?.id){
      history.push(CONSENT_ISOS)
    }
  }, [history.location.pathname,privacyNoticeAccepted,user?.id])

  
  const SHOW_BOTTOM_BAR_PATHS = [DASHBOARD, HOME, HEALTH_PAGE, BENEFITS, IMPROVE_PAGE, PROFILE, MOBILE_ACCOUNT]

  const HIDE_NAVBAR =[]

  return (
    <GlobalContext.Provider value = {[globalState, dispatch]}>
      <ModalContext.Provider value={updateContent}>
        <NavbarContext.Provider value={{data: navbarData, updateData: updateNavbarData}}>
          <LabTestContext.Provider value={[labtestStore,labtestDispatch]}>
            <BookingHistoryContext.Provider value={[bookingHistoryStore, bookingHistorydispatch]}>
              {!hasPrescriptionPending && <Navbar hideNavbar={HIDE_NAVBAR.indexOf(history.location.pathname) !== -1} /> }
              <Main hideMenu={SHOW_BOTTOM_BAR_PATHS.indexOf(history.location.pathname) !== -1} mt={['0px', '100px !important']}>
                <Switch>
                  {privateRoutes()}
                </Switch>
                { profileUpdateModal && <DobAndGenderUpdate customer={user} profileUpdateModal={profileUpdateModal} closeProfileModal={() => setProfileUpdateModal(false)} /> }
              </Main>
              {SHOW_BOTTOM_BAR_PATHS.indexOf(history.location.pathname) !== -1 && !hasPrescriptionPending ? <BottomBar /> : null}
              <LayoutModalsConsumer {...data} updateModal={updateContent}/>
            </BookingHistoryContext.Provider>
          </LabTestContext.Provider>
        </NavbarContext.Provider>
      </ModalContext.Provider>  
    </GlobalContext.Provider>
  )
}


export default Layout;